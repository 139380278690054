import { createSlice } from "@reduxjs/toolkit";

const CmsSlice = createSlice({
    name:"CmsSlice",
    initialState:{
        cmsPageList:{
            isLoading:true,
            data:[]
        },
        cmsPageDetail:{
            isLoading:true,
            data:{
                title:"",
                slug:"",
                image:"",
                content:""
            }
        },
        faqList:{
            isLoading:true,
            data:[]
        },
        faqDetail:{
            isLoading:true,
            data:{
                _id:"",
                question:"",
                answer:""
            }
        },
        cityList:{
            isLoading:true,
            data:[]
        },
        cityEdit:{
            _id:'',
            title:'',
            status:''
        },
        vehicleTypeList:{
            isLoading:true,
            data:[]
        },
        vehicleTypeEdit:{
            _id:'',
            title:'',
            status:''
        }
    },
    reducers:{
        setCmsPageList:(state,action)=>{
            state.cmsPageList=action.payload
        },
        setCmsPageDetail:(state,action)=>{
            state.cmsPageDetail=action.payload
        },
        setFaqList:(state,action)=>{
            state.faqList=action.payload
        },
        setFaqDetail:(state,action)=>{
            state.faqDetail=action.payload
        },
        setCityList:(state,action)=>{
            state.cityList=action.payload
        },
        setCityEdit:(state,action)=>{
            state.cityEdit=action.payload
        },
        setVehicleTypeList:(state,action)=>{
            state.vehicleTypeList=action.payload
        },
        setVehicleTypeEdit:(state,action)=>{
            state.vehicleTypeEdit = action.payload
        }
    }
})
export const {setCityList,setCityEdit,setVehicleTypeList,setVehicleTypeEdit,setCmsPageList,setCmsPageDetail,setFaqList,setFaqDetail} = CmsSlice.actions

export default CmsSlice.reducer