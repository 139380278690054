import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
import { getSettingList, updateSetting } from '../../features/SettingSlice/SettingActions'
const Index = () => {
    const dispatch = useDispatch()
    const settingList = useSelector(state=>state.SettingReducer.list)
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const handelFromSubmit = (values) => {
        // console.log('values',values);
        dispatch(updateSetting(values))
    }
    useEffect(()=>{
        dispatch(getSettingList())
    },[])
    useEffect(()=>{
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    },[apiSuccess,apiFail])
  return (
    <div className='page-content'>
        <div className='section-title'>
            <h4>Setting</h4>
        </div>
        <div className='row'>
            <div className='col-12'>
                <Formik
                    enableReinitialize
                    initialValues={settingList}
                    onSubmit={handelFromSubmit}
                >
                {
                    formProps=>(
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className='col-md-4 mt-2'>
                                    <Form.Group controlId="">
                                        <Form.Label>Booking Intervel Duration (Min.)</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Booking Intervel Duration" 
                                            name="bookingIntDur"
                                            value={formProps.values.bookingIntDur}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.bookingIntDur && formProps.errors.bookingIntDur}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.bookingIntDur}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <Form.Group controlId="">
                                        <Form.Label>Booking Cancel Duration (Hours)</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Booking Cancel Duration" 
                                            name="bookingCancelDur"
                                            value={formProps.values.bookingCancelDur}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.bookingCancelDur && formProps.errors.bookingCancelDur}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.bookingCancelDur}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <Form.Group controlId="">
                                        <Form.Label>Gst (%)</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="GST (%)" 
                                            name="gst"
                                            value={formProps.values.gst}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.gst && formProps.errors.gst}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.gst}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="">
                                        <Form.Label>Store Open Time</Form.Label>
                                        <Form.Control 
                                            type="time"
                                            // placeholder="Booking Cancel Duration" 
                                            name="openTime"
                                            value={formProps.values.openTime}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.openTime && formProps.errors.openTime}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.openTime}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="">
                                        <Form.Label>Store Close Time</Form.Label>
                                        <Form.Control 
                                            type="time"
                                            placeholder="Booking Cancel Duration" 
                                            name="closeTime"
                                            value={formProps.values.closeTime}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.closeTime && formProps.errors.closeTime}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.closeTime}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }
                </Formik>
            </div>
        </div>
    </div>
  )
}

export default Index