import { createSlice } from '@reduxjs/toolkit';


export const userSlice = createSlice({
    name: 'driver',
    initialState: {
        driverList: {},
        driverEdit:{
            firstName:"",
            lastName:"",
            driverId:"",
            email:"",
            mobile:"",
            gender:"",
            status:""   
        },
        driverView:null
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action;
        },
        setDriverList: (state, action) => {
            state.driverList = action.payload;
        },
        // setError: (state, action) => {
        //     state.errorMessage = action;
        // },
        setDriverEdit:(state,action)=>{
            state.driverEdit = action.payload
        },
        setDriverView:(state,action)=>{
            state.driverView = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
// setError,
export const { setDriverList, setLoading, setDriverEdit, setDriverView } = userSlice.actions

export default userSlice.reducer
