import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getBookingChart, getDasboardCount, getPaymentChart, getUserChart, getRefundChart } from '../../features/CommonSlice/CommonActions'
import { setApiFail } from '../../features/CommonSlice/CommonSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarCheck, faUser } from '@fortawesome/free-regular-svg-icons'
import { faBriefcase, faCalendarDay, faCar, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Chart } from "react-google-charts";
export default function Dashboard() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const bookingChartData = useSelector(state => state.commonReducer.bookingChart)
    const userChartData = useSelector(state => state.commonReducer.userChart)
    const paymentChartData = useSelector(state => state.commonReducer.paymentChart)
    const refundChartData = useSelector(state => state.commonReducer.refundChart)
    const apiFail = useSelector(state => state.commonReducer.fail)
    const dashboardCount = useSelector(state => state.commonReducer.dashboardCount)
    const handelChartChange = (e) =>{
        // console.log('value',e.target.value);
        dispatch(getBookingChart({filter:e.target.value}))
        dispatch(getUserChart({filter:e.target.value}))
        dispatch(getPaymentChart({filter:e.target.value}))
        dispatch(getRefundChart({filter:e.target.value}))
    }
    useEffect(() => {
        dispatch(getDasboardCount())
        dispatch(getBookingChart())
        dispatch(getUserChart())
        dispatch(getPaymentChart())
        dispatch(getRefundChart())
    }, [])
    useEffect(() => {
        if (apiFail) {
            toast(apiFail.message, { type: 'error' });
            dispatch(setApiFail(null))
        }
    }, [apiFail])
    
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Dashboard</h4>
            </div>
            <div className='row'>
                <div className='col-3'>
                    <Card
                        role={'button'}
                        onClick={()=>navigate('/admin/booking')}
                        bg="success"
                        text='white'
                        className="mb-2 overflow-hidden">
                        {/* <Card.Header>Header</Card.Header> */}
                        <Card.Body>
                            <Card.Title>Total Booking</Card.Title>
                            <Card.Text className='text-white'>
                                {dashboardCount.bookingCount}
                            </Card.Text>
                            <FontAwesomeIcon icon={faCalendarCheck} className="icon-left userIcon" />
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-3'>
                    <Card
                        role={'button'}
                        onClick={()=>navigate('/admin/users')}
                        bg="primary"
                        text='white'
                        className="mb-2 overflow-hidden">
                        {/* <Card.Header>Header</Card.Header> */}
                        <Card.Body>
                            <Card.Title>Total User's</Card.Title>
                            <Card.Text className='text-white'>
                                {dashboardCount.userCount}
                                <FontAwesomeIcon icon={faUser} className="icon-left userIcon" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-3'>
                    <Card
                        role={'button'}
                        onClick={()=>navigate('/admin/driver')}
                        bg="warning"
                        text='white'
                        className="mb-2 overflow-hidden">
                        {/* <Card.Header>Header</Card.Header> */}
                        <Card.Body>
                            <Card.Title>Total Driver's</Card.Title>
                            <Card.Text className='text-white'>
                                {dashboardCount.driverCount}
                                <FontAwesomeIcon icon={faCar} className="icon-left userIcon" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-3'>
                    <Card
                        role={'button'}
                        onClick={()=>navigate('/admin/package')}
                        bg="info"
                        text='white'
                        className="mb-2 overflow-hidden">
                        {/* <Card.Header>Header</Card.Header> */}
                        <Card.Body>
                            <Card.Title>Total Package's</Card.Title>
                            <Card.Text className='text-white'>
                                {dashboardCount.packageCount}
                                <FontAwesomeIcon icon={faBriefcase} className="icon-left userIcon" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-3'>
                    <Card
                        role={'button'}
                        onClick={()=>navigate('/admin/promo')}
                        bg="danger"
                        text='white'
                        className="mb-2 overflow-hidden">
                        {/* <Card.Header>Header</Card.Header> */}
                        <Card.Body>
                            <Card.Title>Total Promo's</Card.Title>
                            <Card.Text className='text-white'>
                                {dashboardCount.promoCount}
                                <FontAwesomeIcon icon={faMoneyBill} className="icon-left userIcon" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-12'>
                    <Card>
                        <Card.Header className='d-inline-flex'>
                            <h4 className='w-75'>
                                Data Chart's
                            </h4>
                            <select className='form-control w-25' defaultValue="month" onChange={handelChartChange}>
                                <option value="month">Monthly</option>
                                <option value="week">Current Week</option>
                                <option value="curMonth">Current Month</option>
                            </select>
                        </Card.Header>
                        <Card.Body>
                            <div className='row'>
                                <div className='col-6'>
                                    <Card>
                                        <Card.Body>
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="400px"
                                                data={bookingChartData}
                                                options={{                      
                                                    colors: ["#ffc107",'#007bff','#28a745', '#ec7b18', '#dc3545'],
                                                    chart: {
                                                    title: `Monthly total number of booking's of year ${new Date().getFullYear()}`,
                                                    //   subtitle: "in hundred",
                                                    },
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-6'>
                                    <Card>
                                        <Card.Body>
                                            <Chart
                                                chartType="Line"
                                                width="100%"
                                                height="400px"
                                                data={userChartData}
                                                options={{                      
                                                    colors: ["#ffc107",'#007bff','#28a745', '#ec7b18', '#dc3545'],
                                                    chart: {
                                                        title: `Monthly total number of new User's register of year ${new Date().getFullYear()}`,
                                                    //   subtitle: "in hundred",
                                                    },
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-6 mt-3'>
                                    <Card>
                                        <Card.Body>
                                            <Chart
                                                chartType="Line"
                                                width="100%"
                                                height="400px"
                                                data={paymentChartData}
                                                options={{   
                                                    curveType: "function",
                                                    legend: { position: "bottom" },                   
                                                    colors: ["#ffc107",'#28a745', '#dc3545'],
                                                    chart:{
                                                        title: `Monthly total payment's of year ${new Date().getFullYear()}`,
                                                    }
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-6 mt-3'>
                                    <Card>
                                        <Card.Body>
                                            <Chart
                                                chartType="Line"
                                                width="100%"
                                                height="400px"
                                                data={refundChartData}
                                                options={{   
                                                    curveType: "function",
                                                    legend: { position: "bottom" },                   
                                                    colors: ["#ffc107",'#28a745', '#dc3545'],
                                                    chart:{
                                                        title: `Monthly total refund's of year ${new Date().getFullYear()}`,
                                                    }
                                                }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    
                </div>
            </div>
        </div>
    )
}
