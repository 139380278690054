
import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setAuthFailed, setAuthSuccess, setLoading } from "./AuthSlice";
export const loginAction = (param) => async (dispatch) => {
    try {
        // console.log("param", param);
        // Dispatch loader
        dispatch(setLoading(true));
        let res = await Api('POST', `/admin/login`, param);
        if (res.statusCode >= 200 && res.statusCode < 300) {
            localStorage.setItem("token", res.data.token);
            // dispatch success message
            dispatch(setAuthSuccess(res));
        }else{
            dispatch(setAuthFailed(res.message));
        }
    } catch (error) {
        console.log("error", error);
    }
} 
export const checkAuth = () => async dispatch => {
    try {
        dispatch(setLoading(true));
        let res = await Api('POST','/admin/checkauthuser',{})
        switch (res.statusCode) {
            case 200:
                // dispatch success message
                dispatch(setAuthSuccess(res));
                break;
            case 401:
                localStorage.removeItem("token");
                // dispatch success message
                dispatch(setAuthFailed(res.message));
                break;
            case 500:
                // dispatch failed message
                dispatch(setAuthFailed(res.message));
                break;
            default:
                break;
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const updateProfile = (value) => async dispatch =>{
    try {
        let res = await Api('POST', `/admin/update-profile`, value);
        if (res.statusCode==200) {
            dispatch(setAuthSuccess(res));   
            dispatch(apiSuccess(res));
        }else{
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);   
    }
}
export const updatePassword = (value) => async dispatch =>{
    try {
        let res = await Api('POST', `/admin/update-password`, value);
        if (res.statusCode==200) {
            dispatch(apiSuccess(res));
        }else{
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);   
    }
}
export const logoutAction = (res = {message:"Logout successfull"}) => async dispatch => {
    localStorage.removeItem("token");
    dispatch(setAuthFailed(res.message))
}