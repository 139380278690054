import { Api } from "../../services/httpService";
import { apiFail, apiSuccess } from "../CommonSlice/CommonActions";
import { setCmsPageList,setCmsPageDetail,setVehicleTypeList,setVehicleTypeEdit,setCityList,setCityEdit,setFaqDetail,setFaqList } from "./CmsSlice";
/* Cms Page Functions Start */
export const getCmsPageList = () => async dispatch =>{
    try {
        let res = await Api("POST","/admin/cms-page/list",{})
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(setCmsPageList({isLoading:false,data:res.data}))
            dispatch(setCmsPageDetail({isLoading:true,data:{
                title:"",
                slug:"",
                image:"",
                content:""
            }}))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const getCmsPageDetail = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/cms-page/detail",value)
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(setCmsPageDetail({isLoading:false,data:res.data}))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const cmsPageUpdate = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/cms-page/update",value,true)
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
/* Cms Page Functions End */
/* Faq Function Start */
export const getFaqList = () => async dispatch =>{
    try {
        let res = await Api("POST","/admin/faq/list",{})
        if (res.statusCode >= 200 && res.statusCode < 300) {
            dispatch(setFaqList({isLoading:false,data:res.data}))
            dispatch(setFaqDetail({isLoading:true,data:{_id:"",question:"",answer:""}}))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const getFaqDetail = (value) => async dispatch =>{
    try {
       let res = await Api ("POST","/admin/faq/edit",value)
       if (res.statusCode >= 200 && res.statusCode < 300) {
            dispatch(setFaqDetail({isLoading:false,data:res.data}))
       } else {
        dispatch(apiFail(res))
       }
    } catch (error) {
        console.log('error',error);
    }
}
export const addFaq = (value) => async dispatch =>{
    try {
       let res = await Api ("POST","/admin/faq/add",value)
       if (res.statusCode >= 200 && res.statusCode < 300) {
            dispatch(apiSuccess(res))
       } else {
        dispatch(apiFail(res))
       }
    } catch (error) {
        console.log('error',error);
    }
}
export const updateFaq = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/faq/update",value)
        if (res.statusCode >= 200 && res.statusCode < 300) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
/* Faq Function End */
/* City Module Function start */
export const getCityList = () => async dispatch =>{
    try {
        let res = await Api('POST','/admin/city/list',{})
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setCityList({isLoading:false,data:res.data}))
            dispatch(setCityEdit({ _id:'', title:"", status:"" }))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const addCity = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/city/add",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const editCity = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/city/edit",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setCityEdit(res.data))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const updateCity = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/city/update",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
/* City Module Function end */
/* Vehicle Type Module Function start */
export const getVehicleTypeList = () => async dispatch =>{
    try {
        let res = await Api('POST','/admin/vehicle-type/list',{})
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setVehicleTypeList({isLoading:false,data:res.data}))
            dispatch(setVehicleTypeEdit({_id:"",title:'',status:""}))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const addVehicleType = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/vehicle-type/add",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const editVehicleType = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/vehicle-type/edit",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setVehicleTypeEdit(res.data))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
export const updateVehicleType = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/vehicle-type/update",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error);
    }
}
/* Vehicle Type Module Function start */