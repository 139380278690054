
export const Api = async (reqType, apiRouteUrl, body, isFormData = false) => {
    try {

        let tokenHeader = "";
        if (localStorage.getItem("token")) {
            tokenHeader = "Bearer " + localStorage.getItem("token");
        }

        if (isFormData === true) {
            const requestOptions = {
                method: reqType,
                headers: {
                    "Authorization": tokenHeader,
                    "menuUrl": window.localStorage.getItem('menuUrl')
                },
                body: body
            };

            let APIURL = process.env.REACT_APP_API_URL + apiRouteUrl;

            let response = await fetch(APIURL, requestOptions);
            let data = null;
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = await response.json();
            }
            return data;

        } else {
            const requestOptions = {
                method: reqType,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": tokenHeader,
                    "menuUrl": window.localStorage.getItem('menuUrl')
                },
                body: JSON.stringify(body)
            };

            let APIURL = process.env.REACT_APP_API_URL + apiRouteUrl;
            let response = await fetch(APIURL, requestOptions);
            // console.log('response',await response.json());
            let data = [];
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = await response.json();
            }
            return data;
        }

    } catch (err) {
        return { success: err }
    }
}