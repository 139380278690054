import { Formik } from 'formik'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import preview from '../../assets/images/preview.jpg'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { createUser } from '../../features/UserSlice/UserActions'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
const AddUser = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const [ profileImg , setProfileImg ] = useState({
        pre:null,
        value:null
    })
    const handelFileChange = (e) =>{
        setProfileImg({
            pre:URL.createObjectURL(e.target.files[0]),
            value:e.target.files[0]
        })
    }
    const validationSchema = Yup.object().shape({
        firstName:Yup.string().required("First name is required"),
        lastName:Yup.string().required("Last name is required"),
        email:Yup.string().required("Email is required").email("Not a valid mail address"),
        mobile:Yup.string().required("Phone number is required").matches(/^[1-9][0-9]{9,13}$/g,'Phone number is not valid'),
        gender:Yup.string().required("Gender is required"),
        status:Yup.boolean().required('Status is required')
    })
    const handelFormSubmit = (values) =>{
        let formData = new FormData()
        // convert object to form data
        Object.keys(values).forEach(item=>formData.append(item,values[item]))
        if (profileImg.value) {
            formData.append('profilePicture',profileImg.value)
        }
        dispatch(createUser(formData))
    }
    useEffect(() => {
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
            navigate('/admin/users')
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    }, [apiSuccess,apiFail]) 
  return (
    <div className='page-content'>
            <div className='section-title'>
                <h4>Add User</h4>
            </div>
            <Formik
                initialValues={{
                    firstName:"",
                    lastName:"",
                    email:"",
                    mobile:"",
                    gender:"",
                    status:1
                    
                }}
                validationSchema={validationSchema}
                onSubmit={handelFormSubmit}
            >
                {
                    formProps=>(
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="firstName">
                                        <Form.Label>First Name *</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter First name" 
                                            name="firstName"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.firstName && formProps.errors.firstName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.firstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="lastName">
                                        <Form.Label>Last Name *</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Enter Last name" 
                                            name="lastName"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.lastName && formProps.errors.lastName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.lastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Enter Email" 
                                            name="email"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.email && formProps.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="mobile">
                                        <Form.Label>Phone Number *</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Enter Phone Number" 
                                            name="mobile"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.mobile && formProps.errors.mobile}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.mobile}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6 mt-2'>
                                    <Form.Group controlId="gender">
                                        <Form.Label>Gender *</Form.Label>
                                        <Form.Select 
                                            aria-label="Default select example"
                                            name="gender"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.gender && formProps.errors.gender}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.gender}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-4 mt-2'>
                                    <Form.Group controlId="gender">
                                        <Form.Label>Profile (Support Type jpeg, png, jpg)</Form.Label>
                                        <Form.Control type="file" onChange={handelFileChange} />
                                    </Form.Group>
                                </div>
                                <div className='col-md-2 mt-2'>
                                    <img width={150} height={150} src={profileImg.pre?profileImg.pre:preview}/>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <Form.Group controlId="status">
                                        <Form.Label>Status *</Form.Label>
                                        <div key={`inline-radio`} className="mb-3">
                                            <Form.Check
                                                inline
                                                defaultChecked
                                                id={`inline-radio-1`}
                                                label="Active"
                                                type="radio"
                                                name="status"
                                                value={1}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                            />
                                            <Form.Check
                                                inline
                                                id={`inline-radio-2`}
                                                label="Deactive"
                                                type="radio"
                                                name="status"
                                                value={0}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                            />
                                        </div>
                                        {
                                            (formProps.touched.status && formProps.errors.status)?
                                                <div className='validation-error-custome'>
                                                    {formProps.errors.status}
                                                </div>
                                            :
                                                null
                                        }
                                    </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                                
                            </div>
                        </Form>
                    )
                }
                
            </Formik>
            
        </div>
  )
}

export default AddUser