
import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setList, setEdit } from "./SubAdminSlice";

export const getSubAdminList = (page = 1,filterData={}) => async (dispatch) => {
    try {
        let value = Object.assign({},{page:page},filterData)
        let res = await Api('POST', `/admin/sub-admin-list`, value);
        console.log("res", res);
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setList(res.data));
            dispatch(setEdit({ firstName:"", lastName:"", email:"", mobile:"", gender:"", status:"" }))
        }else{
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
} 
export const createSubAdmin = (value) => async dispatch =>{
    // dispatch(setLoading(true));
    try {
        let res = await Api('POST', `/admin/sub-admin-add`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
    

}
export const updateSubAdmin = (value) => async dispatch =>{
    try {
        let res = await Api('POST', `/admin/sub-admin-update`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getSubAdminEdit = (value) => async dispatch => {
    try {
        let res = await Api('POST', `/admin/sub-admin-edit`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setEdit(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const updatePassword = (value) => async dispatch =>{
    try {
        let res = await Api('POST', `/admin/sub-admin-password`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}