import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Form } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDriverList } from "../../features/DriverSlice/DriverActions";
import moment from 'moment'
import { toast } from 'react-toastify';
import { setApiFail } from '../../features/CommonSlice/CommonSlice';
import Pagination from '../commonComponents/pagination/serverSide'
import { Formik } from 'formik';
const User = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading ] = useState(true)
    const [list,setList] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const apiFail = useSelector(state => state.commonReducer.fail)
    var driverList = useSelector((state) => state.driverReducer.driverList);
    const [filterData,setFilterData] = useState({
        name:'',
        email:'',
        mobile:''
    })
    const handelFilter = (values) =>{
        setFilterData(values)
		setLoading(true)
        dispatch(getDriverList(1,values))
    }
    const pageChange = (page) => {
        setLoading(true)
        dispatch(getDriverList(page,filterData))
    }
    // call user data api
    useEffect(() => {
        dispatch(getDriverList());
    }, [])
    useEffect(()=>{
        if ((Object.keys(driverList).length!=0)) {
            setList(driverList.docs)
            setPaginationData({
                totalDocs: driverList.totalDocs,
                limit: driverList.limit,
                page: driverList.page,
                totalPages: driverList.totalPages,
                hasPrevPage: driverList.hasPrevPage,
                hasNextPage: driverList.hasNextPage,
                prevPage: driverList.prevPage,
                nextPage: driverList.nextPage
            })
            setLoading(false)
        }
    },[driverList])
    useEffect(() => {
        if (apiFail) {
            toast(apiFail.message, { type: 'error' });
            dispatch(setApiFail(null))
        }
    }, [apiFail])
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Driver</h4>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={filterData}
                                onSubmit={handelFilter}
                                onReset={handelFilter}
                            >
                                {formProps=>(
                                    <Form onSubmit={formProps.handleSubmit} onReset={formProps.handleReset}>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        name="name"
                                                        value={formProps.values.name}
                                                        onChange={formProps.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control
                                                        name="mobile"
                                                        value={formProps.values.mobile}
                                                        onChange={formProps.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        name="email"
                                                        value={formProps.values.email}
                                                        onChange={formProps.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="col-3">
                                                <Form.Group>
                                                    <label className='form-label d-block opacity-0 mt-1' htmlFor="phoneFilter">Action</label>
                                                    <button type="submit" className="btn btn-sm btn-success ml-2">Filter</button>
                                                    <button type="reset" className="btn btn-sm btn-info ms-2" >Reset</button>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-12'>
                    <Card>
                        <Card.Header>
                            <div className="row">
                                <div className="col text-end">
                                    <Button onClick={() => navigate('/admin/driver/add')} variant="primary">Add Driver</Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered tablespace">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Driver Id</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Mobile</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Created On</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading?
                                                    <tr>
                                                        <td colSpan={9}>
                                                            Loading....
                                                        </td>
                                                    </tr>
                                                :
                                                    list.length==0?
                                                        <tr>
                                                            <td colSpan={9}>
                                                                No data found
                                                            </td>
                                                        </tr>
                                                    :
                                                        list.map((item, index) => (
                                                            <tr key={item?._id}>
                                                                <th scope="row">
                                                                    {
                                                                        (
                                                                            (paginationData.page - 1)* paginationData.limit 
                                                                        ) + index +1
                                                                    }
                                                                </th>
                                                                <td>{item?.driverId}</td>
                                                                <td>{item?.firstName}</td>
                                                                <td>{item?.lastName}</td>
                                                                <td>{item?.mobile}</td>
                                                                <td>{item?.email}</td>
                                                                <td>
                                                                    <Badge
                                                                        bg={item.status ? "success" : "danger"}
                                                                    >
                                                                        {item.status ? "Active" : "Deactive"}
                                                                    </Badge>
                                                                </td>
                                                                <td> {moment(item?.createdAt).format('LLLL')} </td>
                                                                <td>
                                                                    <Link to={`/admin/driver/edit/${item._id}`}>
                                                                        <i className="fas fa-edit"></i>
                                                                    </Link>
                                                                    <Link to={`/admin/driver/view/${item._id}`}>
                                                                        <i className="mx-2 far fa-eye"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination
                                data={paginationData}
                                pagFun={pageChange}
                            />
                        </Card.Footer>
                    </Card>
                </div>
            </div>
            

        </div>
    )
}
export default User;