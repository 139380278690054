import React from 'react'
import { Table, Badge } from 'react-bootstrap'

const VehicleList = ({ list }) => {

    return (
        <Table responsive hover className='tablespace'>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Registration Number and Year</th>
                    <th>Brand</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th>Color</th>
                    <th>primary</th>
                    <th>status</th>
                </tr>
            </thead>
            <tbody>
                {
                    list.length == 0 ?
                        <tr>
                            <td colSpan={8}>No data found</td>
                        </tr>
                        :
                        list.map((item, key) => (
                            <tr key={item._id}>
                                <td>
                                    {key + 1}
                                </td>
                                <td>
                                    {item.registrationNumber} / {item.registrationYear}
                                </td>
                                <td> {item.brand} </td>
                                <td> {item.make} </td>
                                <td> {item.model} </td>
                                <td> {item.color} </td>
                                <td>
                                    <Badge
                                        bg={item.primary ? "success" : "danger"}
                                    >
                                        {item.primary ? 'Yes' : "No"}
                                    </Badge>
                                </td>
                                <td>
                                    <Badge
                                        bg={item.status ? "success" : "danger"}
                                    >
                                        {item.status ? 'Active' : "Deactive"}
                                    </Badge>
                                </td>
                            </tr>
                        ))
                }
            </tbody>
        </Table>
    )
}

export default VehicleList