import { createSlice } from "@reduxjs/toolkit";

const BookingSlice = createSlice({
    name:"BookingSlice",
    initialState:{
        list:{},
        // list:{
        //     isLoading:true,
        //     data:{}
        // },
        detail:{
            isLoading:true,
            data:{}
        },
        availableDriverList:{
            isLoading:true,
            data:[]
        },
        taskList : {
            isLoading:true,
            data:[]
        }
    },
    reducers:{
        setBookingList: (state,actions)=>{
            state.list = actions.payload
        },
        setDriverDetail:(state,action)=>{
            state.detail = action.payload
        },
        setAvailableDriverList:(state,action)=>{
            state.availableDriverList={
                isLoading:false,
                data:action.payload
            }
        },
        unSetAvailableDriverList:(state,action)=>{
            state.availableDriverList={
                isLoading:true,
                data:[]
            }
        },
        setTaskList:(state,action)=>{
            state.taskList = action.payload
        }
    }
})
export const { setBookingList, setAvailableDriverList, unSetAvailableDriverList, setDriverDetail, setTaskList } = BookingSlice.actions
export default BookingSlice.reducer