import { createSlice } from "@reduxjs/toolkit";

const SettingSlice = createSlice({
    name:"SettingSlice",
    initialState:{
        list:{
            bookingIntDur:"",
            bookingCancelDur:"",
            gst:"",
            openTime:"",
            closeTime:""
        },
        timeSlotsList:{
            monday:[],
            tuesday:[],
            wednesday:[],
            thursday:[],
            friday:[],
            saturday:[],
            sunday:[]
        }
    },
    reducers:{
        setSettingList: (state,actions)=>{
            state.list = actions.payload
        },
        setTimeSlotList: (state,actions) =>{
            state.timeSlotsList = actions.payload
        }
    }
})
export const { setSettingList,setTimeSlotList } = SettingSlice.actions
export default SettingSlice.reducer