
import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setDriverEdit, setLoading,setDriverList,setDriverView } from "./DriverSlice";

export const getDriverList = (page=1,filterData={}) => async (dispatch) => {
    try {
        let value = Object.assign({},{page:page},filterData)
        let res = await Api('POST', `/admin/driver-list`, value);
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setDriverList(res.data));
            dispatch(setDriverEdit({ firstName:"", lastName:"",driverId:"", email:"", mobile:"", gender:"", status:"" }))
        }else{
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
} 
export const createDriver = (value) => async dispatch =>{
    dispatch(setLoading(true));
    try {
        let res = await Api('POST', `/admin/driver-add`, value,true);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
            // dispatch(getDriverList())
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
    

}
export const updateDriver = (value) => async dispatch =>{
    dispatch(setLoading(true));
    try {
        let res = await Api('POST', `/admin/driver-update`, value,true);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
            // dispatch(getDriverList())
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
    

}
export const getDriverEdit = (value) => async dispatch => {
    try {
        let res = await Api('POST', `/admin/driver-edit-detail`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setDriverEdit(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getDriverView = (value) => async dispatch => {
    try {
        let res = await Api('POST', `/admin/driver-view`, value);   
        console.log('res',res); 
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setDriverView(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}