import { createSlice } from "@reduxjs/toolkit";
const PromoSlice = createSlice({
    name:"PromoSlice",
    initialState:{
        list:{},
        detail:{
            percentage:"",
            code:"",
            type:"",
            packageId:"",
            description:"",
            startDate:"",
            endDate:"",
            status:""
        },
        packageList:[]
    },
    reducers:{
        setList:(state,action)=>{
            state.list = action.payload
        },
        setDetail:(state,action)=>{
            state.detail = action.payload
        },
        setPackageList:(state,action)=>{
            state.packageList = action.payload
        }
    }
})
export const {setList , setDetail, setPackageList} = PromoSlice.actions
export default PromoSlice.reducer