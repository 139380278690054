import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Badge, Card, Form, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBookingList } from '../../features/BookingSlice/BookingActions'
import DriverAssignModel from './extraComponent/driverAssignModel'
import Pagination from '../commonComponents/pagination/serverSide'
import { Formik } from 'formik'
import { CSVLink } from 'react-csv'
import { Api } from '../../services/httpService'
const Index = () => {
    const dispatch = useDispatch()
    const excelRef = useRef()
    const [csvData,setCsvData] = useState([])
    const [csvDownload,setCsvDownload] = useState(false)
    const csvHeader = [
        { label: 'Vehicle No', key: 'registrationNumber' },
        { label: 'User Name', key: 'user.name' },
        { label: 'User email', key: 'user.email' },
        { label: 'User Mobile', key: 'user.mobile' },
        { label: 'Package', key: 'packageTitle' },
        { label: 'Location', key: 'location' },
        { label: 'Start Date', key: 'startDate' },
        { label: 'End Date', key: 'endDate' },
        { label: 'Time Slot', key: 'time' },
        { label: 'Driver Id', key: 'driver.driverId' },
        { label: 'Driver Name', key: 'driver.name' },
        { label: 'Driver Email', key: 'driver.email' },
        { label: 'Driver Mobile', key: 'driver.mobile' },
        { label: 'Fee', key: 'fee' },
        { label: 'Discont Amount', key: 'discountAmount' },
        { label: 'Amount', key: 'amount' },
        { label: 'Total Amount', key: 'totalAmount' },
        { label: 'Status', key: 'bookingStatus' },
        { label: 'Paymnet Id', key: 'paymentInstance' },
        { label: 'Payment Status', key: 'paymentStatus' },
        { label: 'Refund Id', key: 'refundInstance' },
        { label: 'Refund Status', key: 'refundStatus' },
        { label: 'Created At ', key: 'createdAt' },
    ]
    const [loading, setLoading ] = useState(true)
    const [list,setList] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const bookingList = useSelector(state => state.BookingReducer.list)
    const [driverAssignModel, setDriverAssignModel] = useState({
        status:false,
        bookingId:''
    })
    const [filterData,setFilterData] = useState({
        registrationNumber:'',
        userName:"",
        bookingStatus:""
    })
    const downloadCSV = async ()=>{
        let res = await Api("POST","/admin/booking/csv-list",filterData)
        if (res.statusCode ==200) {
            if (res.data.length==0) {
                alert("No Data found")
            }else{
                setCsvData(res.data)
                setCsvDownload(true)
            }
        }else{
            alert("Server Error")
        }
    }
    const handelFilter = (values) =>{
        setFilterData(values)
		setLoading(true)
        dispatch(getBookingList(1,values))
    }
    const pageChange = (page) => {
        setLoading(true)
        dispatch(getBookingList(page,filterData))
    }
    const handelAssignDriverModel = async (bookingId) =>{
        if (bookingId && bookingId!=='') {
            setDriverAssignModel({
                status:true,
                bookingId:bookingId
            })    
        } else {
            setDriverAssignModel({
                status:false,
                bookingId:''
            })    
        }
    }
    useEffect(() => {
        dispatch(getBookingList())
    }, [])
    useEffect(()=>{
        if (csvDownload) {
            excelRef.current.link.click()
            setCsvDownload(false)
        }
    },[csvDownload])
    useEffect(()=>{
        if ((Object.keys(bookingList).length!=0)) {
            setList(bookingList.docs)
            setPaginationData({
                totalDocs: bookingList.totalDocs,
                limit: bookingList.limit,
                page: bookingList.page,
                totalPages: bookingList.totalPages,
                hasPrevPage: bookingList.hasPrevPage,
                hasNextPage: bookingList.hasNextPage,
                prevPage: bookingList.prevPage,
                nextPage: bookingList.nextPage
            })
            setLoading(false)
        }
    },[bookingList])
    return (
        <div className='page-content'>
            {console.log('paginationData',paginationData)}
            <div className='section-title'>
                <h4>Booking</h4>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={filterData}
                                onSubmit={handelFilter}
                                onReset={handelFilter}
                            >
                                {formProps=>(
                                    <Form onSubmit={formProps.handleSubmit} onReset={formProps.handleReset}>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Vehicle Number</Form.Label>
                                                    <Form.Control
                                                        name="registrationNumber"
                                                        value={formProps.values.registrationNumber}
                                                        onChange={formProps.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>User Name</Form.Label>
                                                    <Form.Control
                                                        name="userName"
                                                        value={formProps.values.userName}
                                                        onChange={formProps.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select
                                                        className='form-control'
                                                        name="bookingStatus"
                                                        value={formProps.values.bookingStatus}
                                                        onChange={formProps.handleChange}
                                                    >
                                                        <option> Select Status</option>
                                                        {
                                                            ["Pending", "Inprocess", "Completed", "Cancelled", "Failed"].map(option=>(
                                                                <option key={option} value={option}>{option}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className="col-3">
                                                <Form.Group>
                                                    <label className='form-label d-block opacity-0 mt-1' htmlFor="phoneFilter">Action</label>
                                                    <button type="submit" className="btn btn-sm btn-success ml-2">Filter</button>
                                                    <button type="reset" className="btn btn-sm btn-info ms-2" >Reset</button>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-12 mt-2'>
                <Card>
                    <Card.Header>
                        <button
                           className='btn btn-success'
                           onClick={downloadCSV} 
                        >
                            Download CSV
                        </button>
                        <CSVLink 
                            filename={`bookinglist_${new Date().getTime()}`}
                            asyncOnClick={true}
                            data={csvData}
                            headers={csvHeader}
                            ref={excelRef}
                            target='_blank'
                        />
                    </Card.Header>
                    <Card.Body>
                        <div className='row'>
                            <div className='col-12'>
                                <Table responsive className='tablespace'>
                                    <thead>
                                        <tr>
                                            <th> # </th>
                                            <th> Vehicle </th>
                                            <th> User </th>
                                            <th> Package </th>
                                            <th> Location </th>
                                            <th> Pick Up Date </th>
                                            <th> Time </th>
                                            <th> Amount </th>
                                            <th> Fee </th>
                                            <th> Status </th>
                                            <th> Payment Status </th>
                                            <th> Driver Assign </th>
                                            <th> Created At </th>
                                            <th> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr>
                                                    <td colSpan={14}>Loading....</td>
                                                </tr>
                                            :
                                                list.length == 0 ?
                                                    <tr>
                                                        <td colSpan={14}>No data found</td>
                                                    </tr>
                                                :
                                                    list.map((item, key) => (
                                                        <tr key={item._id}>
                                                            <td>
                                                            {
                                                                (
                                                                    (paginationData.page - 1)* paginationData.limit 
                                                                ) + key +1
                                                            }
                                                            </td>
                                                            <td>
                                                                {item.vehicle.registrationNumber}
                                                            </td>
                                                            <td>
                                                                {item.userData?.name}
                                                            </td>
                                                            <td>
                                                                {item.package.title}
                                                            </td>
                                                            <td>
                                                                {item.location?.unit} {item.location?.street} {item.location?.city} ({item.location?.zip})
                                                            </td>
                                                            <td>
                                                                {moment(item.startDate).format('LL')}
                                                            </td>
                                                            <td>
                                                                {item.time}
                                                            </td>
                                                            <td>
                                                                {item.amount}
                                                            </td>
                                                            <td>
                                                                {item.fee}
                                                            </td>
                                                            <td>
                                                                {item.bookingStatus}
                                                            </td>
                                                            <td>
                                                                {item.paymentStatus}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.driverAssign?
                                                                        `${item.driverData.firstName} ${item.driverData.lastName}`
                                                                    :
                                                                        item.bookingStatus=='Pending'?
                                                                            <Badge 
                                                                                role="button"
                                                                                onClick={()=>handelAssignDriverModel(item._id)}
                                                                            >
                                                                                Not Assign
                                                                            </Badge>
                                                                        :
                                                                            '-----'
                                                                }
                                                            </td>
                                                            <td>
                                                                {moment(item.createdAt).format('LLLL')}
                                                            </td>
                                                            <td>
                                                                <Link to={`/admin/booking/${item._id}`}>
                                                                    <i className="mx-2 far fa-eye"></i>
                                                                </Link>
                                                                <Link to={`/admin/booking/tasks/${item._id}`}>
                                                                    <i className="mx-2 fa fa-tasks" aria-hidden="true"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            data={paginationData}
                            pagFun={pageChange}
                        />
                    </Card.Footer>
                </Card>
                </div>
            </div>
            
            
            <DriverAssignModel
                status={driverAssignModel.status}
                bookingId={driverAssignModel.bookingId}
                handelAssignDriverModel={handelAssignDriverModel}
            />
        </div>
    )
}

export default Index