import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import CKEdit from '../commonComponents/from/ckeditor'
import { toast } from 'react-toastify'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
import { getFaqDetail, updateFaq } from '../../features/CmsSlice/CmsActions'
const Edit = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const detail = useSelector(state=>state.CmsReducer.faqDetail)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const validationSchema = Yup.object().shape({
        question:Yup.string().required("Title is required"),
        answer:Yup.string().required('Answer is required')
    })
    const handelSubmit = (values) =>{
        dispatch(updateFaq(values))
    }
    useEffect(()=>{
        dispatch(getFaqDetail({_id:params.id}))
    },[])
    useEffect(() => {
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
            navigate('/admin/faq')
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    }, [apiSuccess,apiFail]) 
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Edit Faq</h4>
            </div>
            <Formik
                enableReinitialize
                initialValues={detail.data}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
            >
                {formProps=>(
                    <Form onSubmit={formProps.handleSubmit}>
                        <div className='row'>
                            <div className="col-12">
                                <Form.Group>
                                    <Form.Label>Question *</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='question'
                                        placeholder='Enter Question'
                                        value={formProps.values.question}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        isInvalid={formProps.touched.question && formProps.errors.question}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formProps.errors.question}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col-12">
                                <Form.Group>
                                    <Form.Label>Answer *</Form.Label>
                                    {
                                        (!detail.isLoading)?
                                            <CKEdit
                                                name="answer"
                                                getValue={formProps.setFieldValue}
                                                defValue={detail.data.answer}
                                                error={formProps.errors.answer}
                                                errorStatus={formProps.touched.answer && formProps.errors.answer}
                                            />
                                        :
                                            <p>Loading .....</p>
                                    }
                                </Form.Group>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                    
                )}
            </Formik>
        </div>
    )
}

export default Edit