import React from "react";
import { Route, Routes } from "react-router-dom";
import SideBar from "../../layouts/Sidebar/SideBar";
import Header from "../../layouts/Header/Header";

import Dashboard from "../../components/dashboard/Dashboard";
import Profile from '../../components/auth/Profile'
/* user components start */
import User from "../../components/users";
import UserAdd from "../../components/users/add";
import UserEdit from "../../components/users/edit";
import UserView from "../../components/users/detail";
/* user components end */
/* sub-admin components start */
import SubAdmin from "../../components/SubAdmin";
import SubAdminAdd from "../../components/SubAdmin/add";
import SubAdminEdit from "../../components/SubAdmin/edit";
// import SubAdminView from "../../components/SubAdmin/detail";
/* sub-admin components end */
/* driver components start */
import Driver from "../../components/Driver";
import DriverAdd from "../../components/Driver/add";
import DriverEdit from "../../components/Driver/edit";
import DriverView from "../../components/Driver/detail";
/* driver components end */

/** Packages component start */
import Packages from "../../components/packages";
import PackageAddEdit from "../../components/packages/PackageAddEdit";
import PackageView from "../../components/packages/PackageView";
/** Packages component end */

/* Booking components start */
import Booking from "../../components/booking";
import BookingDetail from "../../components/booking/detail";
import BookingTasks from "../../components/booking/Tasks";
/* Booking components end */

/* Setting components start */
import Setting from "../../components/setting";
/* Setting components end */
/* Timeslot components start */
import Timeslot from "../../components/timeSlot";
/* Timeslot components end */
/* City components start */
import CityList from '../../components/Cities'
import CityAdd from '../../components/Cities/add'
import CityEdit from '../../components/Cities/edit'
/* City components end */
/* Vehicle Type components start */
import VehicleTypeList from '../../components/VehicleType'
import VehicleTypeAdd from '../../components/VehicleType/add'
import VehicleTypeEdit from '../../components/VehicleType/edit'
/* Vehicle Type components end */
/* Cms Page Components start */
import CmsPageList from '../../components/cmsPage'
import CmsPageEdit from '../../components/cmsPage/edit'
/* Cms Page Components end */
/* FAQ Page Components start */
import FaqList from '../../components/FAQ'
import FaqAdd from '../../components/FAQ/add'
import FaqEdit from '../../components/FAQ/edit'
/* FAQ Page Components end */
/* Promocode Components start */
import PromoList from '../../components/PromoCode'
import PromoAdd from '../../components/PromoCode/add'
import PromoEdit from '../../components/PromoCode/edit'
/* Promocode Components end */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Private = (props) => {
	return (
		<div className="main-wrapper">
			<ToastContainer
				position="top-right"
				// hideProgressBar={true}
				autoClose={3000}
				closeOnClick
				draggable
			/>
			<Header />
			<SideBar />
			<div className="main-content">
				<Routes>
					<Route exact path="/" element={<Dashboard />} />
					<Route exact path="/admin/profile" element={<Profile />} />
					{/* User's route start */}
					<Route exact path="/admin/users" element={<User />} />
					<Route exact path="/admin/users/add" element={<UserAdd />} />
					<Route path="/admin/users/edit/:id" element={<UserEdit />} />
					<Route path="/admin/users/view/:id" element={<UserView />} />
					{/* User's route end */}
					{/* SubAdmin's route start */}
					<Route exact path="/admin/sub-admin" element={<SubAdmin />} />
					<Route exact path="/admin/sub-admin/add" element={<SubAdminAdd />} />
					<Route path="/admin/sub-admin/edit/:id" element={<SubAdminEdit />} />
					{/* <Route path="/admin/driver/view/:id" element={<SubAdminView />} /> */}
					{/* SubAdmin's route end */}
					{/* Driver's route start */}
					<Route exact path="/admin/driver" element={<Driver />} />
					<Route exact path="/admin/driver/add" element={<DriverAdd />} />
					<Route path="/admin/driver/edit/:id" element={<DriverEdit />} />
					<Route path="/admin/driver/view/:id" element={<DriverView />} />
					{/* Driver's route end */}
					{/** Packages route Start*/}
					<Route exact path="/admin/package" element={<Packages />} />
					<Route exact path="/admin/package/add" element={<PackageAddEdit />} />
					<Route path="/admin/package/edit/:id" element={<PackageAddEdit />} />
					{/* <Route path="/admin/package/view/:id"	element={<PackageView />} /> */}
					{/**Packages route End */}
					{/* Booking Route start */}
					<Route exact path="/admin/booking" element={<Booking />} />
					<Route path="/admin/booking/:id" element={<BookingDetail />} />
					<Route path="/admin/booking/tasks/:id" element={<BookingTasks />} />
					{/* Booking Route end */}
					{/* Slot Routes start */}
					<Route exact path="/admin/timeslot" element={<Timeslot />} />
					{/* Slot Routes end */}
					{/* Setting Route Start */}
					<Route exact path="/admin/setting" element={<Setting />} />
					{/* Setting Route End */}

					{/* City Routes Start */}
					<Route exact path="/admin/city" element={<CityList/>} />
					<Route exact path="/admin/city/add" element={<CityAdd/>} />
					<Route path="/admin/city/edit/:id" element={<CityEdit/>} />
					{/* City Routes end */}
					{/* Vehicle Type Routes Start */}
					<Route exact path="/admin/vehicle-type" element={<VehicleTypeList/>} />
					<Route exact path="/admin/vehicle-type/add" element={<VehicleTypeAdd/>} />
					<Route path="/admin/vehicle-type/edit/:id" element={<VehicleTypeEdit/>} />
					{/* Vehicle Type Routes end */}
					{/* Cms Routes Start */}
					<Route exact path="/admin/cms-page" element={<CmsPageList/>} />
					<Route path="/admin/cms-page/edit/:slug" element={<CmsPageEdit/>} />
					{/* Cms Routes End */}
					{/* FAQ Routes Start */}
					<Route exact path="/admin/faq" element={<FaqList/>} />
					<Route exact path="/admin/faq/add" element={<FaqAdd/>} />
					<Route path="/admin/faq/edit/:id" element={<FaqEdit/>} />
					{/* FAQ Routes End */}
					{/* PromoCode Routes Start */}
					<Route exact path="/admin/promo" element={<PromoList />} />
					<Route exact path="/admin/promo/add" element={<PromoAdd />} />
					<Route path="/admin/promo/edit/:id" element={<PromoEdit />} />
					{/* PromoCode Routes End */}
				</Routes>
			</div>
		</div>
	);
};

export default Private;
