import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { editCity,updateCity } from '../../features/CmsSlice/CmsActions'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
const Edit = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const parems = useParams()
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const editDetail = useSelector(state=>state.CmsReducer.cityEdit)
    const validationSchema = Yup.object().shape({
        title:Yup.string().required("City is required"),
        status:Yup.boolean().required("Status is required")
    })
    const handelSubmit = (values) => {
        dispatch(updateCity(values))
    }
    useEffect(()=>{
        dispatch(editCity({_id:parems.id}))
    },[])
    useEffect(() => {
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
            navigate('/admin/city')
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    }, [apiSuccess,apiFail]) 
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>
                Edit Cities
                </h4>
            </div>
            <Formik
                enableReinitialize
                initialValues={editDetail}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
            >
                {
                    formProps=>(
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className='col-12 mb-2'>
                                    <Form.Group controlId='title'>
                                        <Form.Label>City Name *</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Enter City Name'
                                            name='title'
                                            value={formProps.values.title}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={
                                                formProps.touched.title && formProps.errors.title
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <Form.Group controlId="status">
                                        <Form.Label>Status *</Form.Label>
                                        { 
                                            editDetail.status!==''?
                                                <div key={`inline-radio`} className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        defaultChecked={editDetail.status?true:false}
                                                        id={`inline-radio-1`}
                                                        label="Active"
                                                        type="radio"
                                                        name="status"
                                                        value={1}
                                                        onChange={formProps.handleChange}
                                                        onBlur={formProps.handleBlur}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        defaultChecked={!editDetail.status?true:false}
                                                        id={`inline-radio-2`}
                                                        label="Deactive"
                                                        type="radio"
                                                        name="status"
                                                        value={0}
                                                        onChange={formProps.handleChange}
                                                        onBlur={formProps.handleBlur}
                                                    />
                                                </div>
                                            :
                                                null
                                        }
                                        {
                                            (formProps.touched.status && formProps.errors.status)?
                                                <div className='validation-error-custome'>
                                                    {formProps.errors.status}
                                                </div>
                                            :
                                                null
                                        }
                                    </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}

export default Edit