import { createSlice } from '@reduxjs/toolkit';


export const subAdminSlice = createSlice({
    name: 'subAdmin',
    initialState: {
        list: {},
        edit:{
            firstName:"",
            lastName:"",
            email:"",
            mobile:"",
            gender:"",
            status:""   
        },
    },
    reducers: {
        setList: (state, action) => {
            state.list = action.payload;
        },
        setEdit:(state,action)=>{
            state.edit = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
// setError,
export const { setList, setEdit } = subAdminSlice.actions

export default subAdminSlice.reducer
