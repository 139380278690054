import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faBook, faCog, faCar, faChevronRight, faBriefcase, faFile, faMoneyBill, faBuilding, faList } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

export default function SideBar() {
  const location = useLocation()
  const [open, setOpen] = useState({
    userMenu:false
  });
  const activeSideMenu=[
    { path:"/admin/users",state:"userMenu" },
    { path:"/admin/driver",state:"userMenu" },
    { path:"/admin/sub-admin",state:"userMenu" },
    { path:"/admin/cms-page",state:"cmsMenu" },
    { path:"/admin/faq",state:"cmsMenu" },
  ]
  const pathActive = (value)=>{
    return (location.pathname.startsWith(value))
  }
  useEffect(()=>{
    let temp = {...open}
    Object.keys(open).forEach(i => {
      temp[i] = false
    });
    activeSideMenu.forEach((obj => {
      if (pathActive(obj.path)) {
        temp[obj.state]=true
      }
    }));
    setOpen(temp)
  },[location])
  const handelMenuOpen=(value)=>{
    let temp = {...open}
    Object.keys(temp).forEach(key=>{
      if (key!==value) {        
        temp[key]=false
      }
    })
    temp[value] = !temp[value];
    setOpen(temp)
  }
  return (
    <aside className="left-sidebar">
      <div className="left-sidebar-content">
        <div className="sidebar-menu">
          <ul className="sidemenu">
            <li className="active">
              <Link to="/">
                <FontAwesomeIcon icon={faHouse} className="icon-left" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <span
                  onClick={() => handelMenuOpen('userMenu')}
                  aria-controls="example-collapse-text"
                  aria-expanded={open.userMenu}
                  className={`collapse-btn ${open.userMenu?"active":""}`}
              >
                  <FontAwesomeIcon icon={faUser} className="icon-left" />
                  <span>User's Manager</span>
                  <span className='menu-arrow'>
                      <FontAwesomeIcon icon={faChevronRight} />
                  </span>
              </span>
              <Collapse in={open.userMenu}>
                  <div id="example-collapse-text">
                      <ul className='sidebar-submenu'>
                          <li className={pathActive('/admin/sub-admin')?'active':''}>
                              <Link to="/admin/sub-admin"><span>Sub Admin</span></Link>
                          </li>
                          <li className={pathActive('/admin/users')?'active':''}>
                              <Link to="/admin/users"><span>Users</span></Link>
                          </li>
                          <li className={pathActive('/admin/driver')?'active':''}>
                              <Link to="/admin/driver"><span>Driver</span></Link>
                          </li>
                          
                      </ul>
                  </div>
              </Collapse>
            </li>
            <li className={pathActive('/admin/package')?'active':''}>
              <Link to="/admin/package">
                <FontAwesomeIcon icon={faBriefcase} className="icon-left" />
                <span>Packages</span>
              </Link>
            </li>
            <li className={pathActive('/admin/promo')?'active':''}>
              <Link to="/admin/promo">
                <FontAwesomeIcon icon={faMoneyBill} className="icon-left" />
                <span>Promocode</span>
              </Link>
            </li>
            <li className={pathActive('/admin/booking')?'active':''}>
              <Link to="/admin/booking">
                <FontAwesomeIcon icon={faBook} className="icon-left" />
                <span>Bookings</span>
              </Link>
            </li>
            <li className={pathActive('/admin/timeslot')?'active':''}>
              <Link to="/admin/timeslot">
                <FontAwesomeIcon icon={faBook} className="icon-left" />
                <span>Time Slot</span>
              </Link>
            </li>
            <li className={pathActive('/admin/setting')?'active':''}>
              <Link to="/admin/setting">
                <FontAwesomeIcon icon={faCog} className="icon-left" />
                <span>Setting</span>
              </Link>
            </li>
            <li className={pathActive('/admin/city')?'active':''}>
                <Link to="/admin/city">
                  <FontAwesomeIcon icon={faBuilding} className="icon-left" />
                  <span>Cities</span>
                </Link>
            </li>
            <li className={pathActive('/admin/vehicle-type')?'active':''}>
                <Link to="/admin/vehicle-type">
                  <FontAwesomeIcon icon={faList} className="icon-left" />
                  <span>Vehicle Type</span>
                </Link>
            </li>
            <li>
              <span
                  onClick={() => handelMenuOpen('cmsMenu')}
                  aria-controls="example-collapse-text"
                  aria-expanded={open.cmsMenu}
                  className={`collapse-btn ${open.cmsMenu?"active":""}`}
              >
                  <FontAwesomeIcon icon={faFile} className="icon-left" />
                  <span>CMS Manager</span>
                  <span className='menu-arrow'>
                      <FontAwesomeIcon icon={faChevronRight} />
                  </span>
              </span>
              <Collapse in={open.cmsMenu}>
                  <div id="example-collapse-text">
                      <ul className='sidebar-submenu'>
                          <li className={pathActive('/admin/cms-page')?'active':''}>
                              <Link to="/admin/cms-page"><span>Cms Page</span></Link>
                          </li>
                          <li className={pathActive('/admin/faq')?'active':''}>
                              <Link to="/admin/faq"><span>Faq</span></Link>
                          </li>
                      </ul>
                  </div>
              </Collapse>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
}
