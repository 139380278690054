import { createSlice } from "@reduxjs/toolkit";

const PackageSlice = createSlice({
	name: "PackageSlice",
	initialState: {
		packageList: {},
		packageDetail:{
			vehicleType:"",
			city:"",
			title: "",
			description: "",
			price: "",
			zipcodes:[],
			status: "",
			totalWash:"",
			interval:{
				type:'',
				quantity:''
			},
			options:[{
				name:"",
				price:"",
				description:['']
			}]
		},
		packageExtraData:{
			city:[],
			vehicleType:[]
		}
	},
	reducers: {
		setPackageList: (state, action) => {
			state.packageList = action.payload;
		},
		setPackageDetail:(state,action)=>{
			state.packageDetail = action.payload
		},
		setExtraData:(state,action)=>{
			state.packageExtraData=action.payload
		}
	},
});

export const { setPackageList, setPackageDetail,setExtraData } = PackageSlice.actions;
export default PackageSlice.reducer;
