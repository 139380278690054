import moment from 'moment'
import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getBookingDetail } from '../../features/BookingSlice/BookingActions'
import preview from '../../assets/images/preview.jpg'
const Detail = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const detail = useSelector(state=>state.BookingReducer.detail)
    const calculatePackagePrice = (options)=>{
        let price = 0
        options.forEach(item=>{
            if('value' in item && item.value){
                price+=Number(item.price)
            }
        })
        return price
    }
    useEffect(()=>{
        if (params.id) {
            dispatch(getBookingDetail({_id:params.id}))
        }
    },[params])
    return (
        <div className='page-content'>
            <div className='section-title pb-0'>
                <h4>Booking Detail</h4>
            </div>
            {
                detail.isLoading?
                    <div>Loading ......</div>
                :
                <>
                    <Card>
                        <Card.Header as="h3">
                            General Detail
                        </Card.Header>
                        <Card.Body>
                            <div className='row'>
                                {/* <div className='col-3 mb-4'>
                                    Status: <b className='text-end'>{detail.data.bookingStatus}</b>
                                </div> */}
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            Status
                                        </div>
                                        <div className='col-9'>
                                            <b>{detail.data.bookingStatus}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            Amount
                                        </div>
                                        <div className='col-9'>
                                            <b>{detail.data.amount}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            Fee
                                        </div>
                                        <div className='col-9'>
                                            <b>{detail.data.fee}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            Total Amount
                                        </div>
                                        <div className='col-9'>
                                            <b>{detail.data.totalAmount}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            Start Date
                                        </div>
                                        <div className='col-9'>
                                            <b>{ moment(detail.data.startDate).format("LLLL")}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            End Date
                                        </div>
                                        <div className='col-9'>
                                            <b>{ moment(detail.data.endDate).format("LLLL")}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-4'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            Time Slot
                                        </div>
                                        <div className='col-9'>
                                            <b>{ detail.data.time }</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-4'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            Location
                                        </div>
                                        <div className='col-11'>
                                            <b>{detail.data.location?.unit} {detail.data.location?.street} {detail.data.location?.city} ({detail.data.location?.zip})</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-4'>
                                    <div className='row'>
                                        <div className='col-12 mb-4'>
                                            <h5>Payment Details</h5>
                                        </div>
                                        <div className='col-2 mb-4'>
                                            Payment Id
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <b>{detail.data.paymentInstance}</b>
                                        </div>
                                        <div className='col-2 mb-4'>
                                            Payment Status
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <b>{detail.data.paymentStatus}</b>
                                        </div>
                                        {
                                            detail.data.refundInstance?
                                                <>
                                                    <div className='col-12 mb-4'>
                                                        <h5>Refund Details</h5>
                                                    </div>
                                                    <div className='col-2 mb-4'>
                                                        Refund Id
                                                    </div>
                                                    <div className='col-4 mb-4'>
                                                        <b>{detail.data.refundInstance}</b>
                                                    </div>
                                                    <div className='col-2 mb-4'>
                                                        Refund Status
                                                    </div>
                                                    <div className='col-4 mb-4'>
                                                        <b>{detail.data.refundStatus}</b>
                                                    </div>
                                                </>
                                            :
                                                null
                                        }
                                        <div className='col-12 mb-4'>
                                            <h5>Rating And Review</h5>
                                        </div>
                                        <div className='col-2 mb-4'>
                                            Rating
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <b>{detail.data.reviewData.rating}</b>
                                        </div>
                                        <div className='col-2 mb-4'>
                                            Review
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <b>{detail.data.reviewData.review}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className='my-2'>
                        <Card.Header as="h3">
                            Package Detail
                        </Card.Header>
                        <Card.Body>
                            {
                                Object.keys(detail.data.package).length===0?
                                    <div>
                                        <h3>No Data Found</h3>
                                    </div>
                                :
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-6 mb-4'>
                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            Title
                                                        </div>
                                                        <div className='col-8'>
                                                            <b>{detail.data.package.title}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 mb-4'>
                                                    <div className='row'>
                                                        <div className='col-4'>
                                                            Price
                                                        </div>
                                                        <div className='col-8'>
                                                            <b>{ calculatePackagePrice(detail.data.package.options) }</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-4'>
                                                    <div className='row'>
                                                        <div className='col-2'>
                                                            Description
                                                        </div>
                                                        <div className='col-10'>
                                                            <b>{detail.data.package.description }</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 mb-4'>
                                                    <div className='row'>
                                                        <div className='col-12 mb-3'>
                                                            Selected Options
                                                        </div>
                                                        {
                                                            detail.data.package.options.map(item=>(
                                                                item.value?
                                                                    <div key={item._id} className='col-6'>
                                                                        <ul className='d-flex flex-wrap'>
                                                                            <li className='me-4'>
                                                                                Name : <b> {item.name}</b>
                                                                            </li>
                                                                            <li>
                                                                                Price : <b> {item.price}</b>
                                                                            </li>
                                                                        </ul>      
                                                                    </div>
                                                                :
                                                                    null
                                                                
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                            }
                        </Card.Body>
                    </Card>
                    <Card className='my-2'>
                        <Card.Header as="h3">
                            Vehicle Detail
                        </Card.Header>
                        <Card.Body>
                            {
                                Object.keys(detail.data.vehicle).length===0?
                                    <div>
                                        <h3>No Data Found</h3>
                                    </div>
                                :
                                    <div className='row'>
                                        <div className='col-4 mb-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    Registration Number
                                                </div>
                                                <div className='col-6'>
                                                    <b>{detail.data.vehicle.registrationNumber}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    Registration Year
                                                </div>
                                                <div className='col-6'>
                                                    <b>{detail.data.vehicle.registrationYear}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    Color
                                                </div>
                                                <div className='col-6'>
                                                    <b>{detail.data.vehicle.color}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    Brand
                                                </div>
                                                <div className='col-6'>
                                                    <b>{detail.data.vehicle.brand}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 mb-4'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    Make / Model
                                                </div>
                                                <div className='col-6'>
                                                    <b>{detail.data.vehicle.make} / {detail.data.vehicle.model}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </Card.Body>
                    </Card>
                    <div className='row'>
                        <div className='col-6 my-2'>
                            <Card>
                                <Card.Header as='h3'> Driver Detail </Card.Header>
                                <Card.Body>
                                    {
                                        Object.keys(detail.data.driverData).length==0?
                                            <div>No Driver assign</div>
                                        :
                                            <div className='row'>
                                                <div className='col-6 mt-2'>
                                                    Driver Id: <b>{ detail.data.driverData.driverId }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Name: <b>{ detail.data.driverData.name }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Gender: <b>{ detail.data.driverData.gender }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Email: <b>{ detail.data.driverData.email }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Mobile: <b>{ detail.data.driverData.mobile }</b>
                                                </div>
                                            </div>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-6 my-2'>
                            <Card>
                                <Card.Header as='h3'> User Detail </Card.Header>
                                <Card.Body>
                                    {
                                        Object.keys(detail.data.userData).length==0?
                                            <div>No User Found</div>
                                        :
                                            <div className='row'>
                                                <div className='col-6 mt-2'>
                                                    Name: <b>{ detail.data.userData.name }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Gender: <b>{ detail.data.userData.gender }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Email: <b>{ detail.data.userData.email }</b>
                                                </div>
                                                <div className='col-6 mt-2'>
                                                    Mobile: <b>{ detail.data.userData.mobile }</b>
                                                </div>
                                            </div>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </>
            }
            
        </div>
    )
}

export default Detail