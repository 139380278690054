import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setAvailableDriverList, setBookingList,setDriverDetail, setTaskList } from "./BookingSlice";
export const getBookingList = (page = 1,filterData={}) => async dispatch =>{
    try {
        let value = Object.assign({},{page:page},filterData)
        let res = await Api("POST","/admin/booking/list",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setBookingList(res.data))
            // unset Booking Detail and Task list state
            dispatch(setDriverDetail({isLoading:true,data:{}}))
            dispatch(setTaskList({isLoading:true,data:[]}))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getBookingDetail = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/booking/detail",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setDriverDetail({isLoading:false,data:res.data}))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getAvailableDriver = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/booking/availabledriver",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setAvailableDriverList(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const assignDriverToBooking = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/booking/assignDriver",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getTaskList = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/booking/tasks",value)
        if (res.statusCode >= 200 && res.statusCode <300) {
            dispatch(setTaskList({isLoading:false,data:res.data}))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error)
    }
}