import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
import { addPromo, getPackageList } from '../../features/PromoSlice/PromoActions'
import DatePicker from 'react-datepicker'
import moment from 'moment'
const Add = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const apiSuccess = useSelector(state=>state.commonReducer.success)
  const apiFail = useSelector(state=>state.commonReducer.fail)
  const packageList = useSelector(state=>state.PromoReducer.packageList)
  const validationSchema = Yup.object().shape({
    percentage:Yup.number().typeError("Must be a Number").required("Promo discount is required").integer("Must be a integer").positive("Must be a positive value").min(1,"Value must be a number between 1 to 100").max(100,"Value must be a number between 1 to 100"),
    code:Yup.string().required("Promo Code is required"),
    type:Yup.number().typeError("Not a valid promo type").required("Promo type is required"),
    packageId:Yup.string().when('type',{
      is:2,
      then:Yup.string().required("Package is required")
    }),
    description:Yup.string().required("Description is required"),
    startDate:Yup.string().required("Promo Expire Date range is required"),
    endDate:Yup.string().required("Promo Expire Date range is required"),
    status:Yup.boolean().required("Status is required")
  })
  const handelSubmit = (values)=>{
    dispatch(addPromo(values))
  }
  useEffect(()=>{
    dispatch(getPackageList())
  },[])
  useEffect(() => {
    if (apiSuccess) {
        toast(apiSuccess.message,{ type:'success' });
        dispatch(setApiSuccess(null))
        navigate('/admin/promo')
    }
    if (apiFail) {
        toast(apiFail.message,{ type:'error' });
        dispatch(setApiFail(null))
    }
}, [apiSuccess,apiFail]) 
  return (
    <div className="page-content">
      <div className="section-title">
          <h4>Add Promo</h4>
      </div>
      <Formik
        initialValues={{
          percentage:"",
          code:"",
          type:"",
          packageId:"",
          description:"",
          startDate:"",
          endDate:"",
          status:1
        }}
        validationSchema={validationSchema}
        onSubmit={handelSubmit}
      >
        {formProps=>(
          <Form onSubmit={formProps.handleSubmit} >
            <div className='row'>
              <div className='col-6 mb-2'>
                <Form.Group>
                  <Form.Label> Promo Discount * (%)</Form.Label>
                  <Form.Control
                    type='text'
                    name="percentage"
                    placeholder='Enter Promo Discount (%)'
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    isInvalid={formProps.touched.percentage && formProps.errors.percentage}
                  />
                  <Form.Control.Feedback type="invalid">
                      { formProps.errors.percentage }
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className='col-6 mb-2'>
                <Form.Group>
                  <Form.Label> Promo Code *</Form.Label>
                  <Form.Control
                    type='text'
                    name="code"
                    placeholder='Enter Promo Code'
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    isInvalid={formProps.touched.code && formProps.errors.code}
                  />
                  <Form.Control.Feedback type="invalid">
                      { formProps.errors.code }
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className='col-6 mb-2'>
                <Form.Group>
                  <Form.Label> Promo Type *</Form.Label>
                  <Form.Select
                    type='text'
                    name="type"
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    isInvalid={formProps.touched.type && formProps.errors.type}
                  >
                    <option value="">Select Promo Type</option>
                    <option value="0">All</option>
                    <option value="1">For First Time User</option>
                    <option value="2">For Package</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                      { formProps.errors.type }
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className='col-6 mb-2'>
                <Form.Group>
                  <Form.Label> Promo Package</Form.Label>
                  {
                      formProps.values.type==2?
                        <Form.Select
                          type='text'
                          name="packageId"
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          isInvalid={formProps.touched.packageId && formProps.errors.packageId}
                        >
                          <option value="">Select Promo Package</option>
                          {
                            packageList.map(option=>(
                              <option key={option.value} value={option.value}>{option.label}</option>
                            ))
                          }
                        </Form.Select>
                      :
                        <Form.Select
                          type='text'
                          name="packageId"
                          disabled
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          isInvalid={formProps.touched.packageId && formProps.errors.packageId}
                        >
                          <option value="">Select Promo Package</option>
                        </Form.Select>
                  }
                  
                  <Form.Control.Feedback type="invalid">
                      { formProps.errors.packageId }
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className='col-12 mb-2'>
                  <Form.Group>
                    <Form.Label> Description *</Form.Label>
                    <Form.Control
                      type='textarea'
                      name="description"
                      placeholder='Enter Promo Description'
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      isInvalid={formProps.touched.description && formProps.errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                        { formProps.errors.description }
                    </Form.Control.Feedback>
                  </Form.Group>
              </div>
              <div className='col-6 mb-2'>
                <Form.Group controlId="status">
                  <Form.Label>Start and End Date *</Form.Label>
                  <DatePicker
                    className='form-control'
                    minDate={new Date()}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      formProps.setFieldValue('startDate',start?moment(start).format("DD-MM-YYYY"):"");
                      formProps.setFieldValue('endDate',end?moment(end).format("DD-MM-YYYY"):"");
                    }}
                    startDate={formProps.values.startDate!==""?moment(formProps.values.startDate,"DD-MM-YYYY")._d:null}
                    endDate={formProps.values.endDate!==""?moment(formProps.values.endDate,"DD-MM-YYYY")._d:null}
                    selectsRange
                  />
                  {
                      (formProps.touched.startDate && formProps.errors.startDate)?
                          <div className='validation-error-custome'>
                              {formProps.errors.startDate}
                          </div>
                      :
                        (formProps.touched.endDate && formProps.errors.endDate)?
                          <div className='validation-error-custome'>
                              {formProps.errors.endDate}
                          </div>
                      :
                          null
                  }
                </Form.Group>
              </div>
              <div className='col-6 mb-2'>
                <Form.Group controlId="status">
                  <Form.Label>Status *</Form.Label>
                  <div key={`inline-radio`} className="mt-3 mb-2">
                      <Form.Check
                          inline
                          defaultChecked
                          id={`inline-radio-1`}
                          label="Active"
                          type="radio"
                          name="status"
                          value={1}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                      />
                      <Form.Check
                          inline
                          id={`inline-radio-2`}
                          label="Deactive"
                          type="radio"
                          name="status"
                          value={0}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                      />
                  </div>
                  {
                      (formProps.touched.status && formProps.errors.status)?
                          <div className='validation-error-custome'>
                              {formProps.errors.status}
                          </div>
                      :
                          null
                  }
                </Form.Group>
              </div>
              <div className='col-md-12 mt-2'>
                  <Button variant="primary" type="submit">
                      Submit
                  </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Add