import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCmsPageList } from '../../features/CmsSlice/CmsActions'

const Index = () => {
    const dispatch = useDispatch()
    const list = useSelector(state=>state.CmsReducer.cmsPageList)
    useEffect(()=>{
        dispatch(getCmsPageList())
    },[])
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Cms Page List</h4>
            </div>
            <Card>
                <Card.Body>
                    <div className='row'>
                        <div className="table-responsive">
                            <table className="table table-bordered tablespace">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Slug</th>
                                        <th>Title</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.isLoading?
                                            <tr>
                                                <td colSpan={4}>
                                                    Loading.....
                                                </td>
                                            </tr>
                                        :
                                            list.data.length==0?
                                                <tr>
                                                    <td colSpan={4}>
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            :
                                                list.data.map((item,idx)=>(
                                                    <tr key={item.slug}>
                                                        <td>
                                                            {idx+1}
                                                        </td>
                                                        <td>
                                                            {item.slug}
                                                        </td>
                                                        <td>
                                                            {item.title}
                                                        </td>
                                                        <td>
                                                            <Link to={`/admin/cms-page/edit/${item.slug}`}>
                                                                <i className="fas fa-edit"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Index