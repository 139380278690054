import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setSettingList, setTimeSlotList } from "./SettingSlice";
export const getSettingList = () => async dispatch =>{
    try {
        let res = await Api("POST","/admin/setting",{})
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setSettingList(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const updateSetting = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/setting/update",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
            dispatch(setSettingList(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getTimeSlots = () => async dispatch => {
    try {
        let res = await Api("POST","/admin/timeslot/list",{})
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setTimeSlotList(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const updateTimeSlot = (value) => async dispatch =>{
    try {
        let res = await Api("POST","/admin/timeslot/update",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
            dispatch(setTimeSlotList(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}