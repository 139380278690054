import moment from 'moment'
import React from 'react'
import { Table } from 'react-bootstrap'
const BookingTable = ({list}) => {
  return (
    <Table responsive hover className='tablespace'>
            <thead>
                <tr>
                    <th> # </th>
                    <th> Vehicle </th>
                    <th> Package </th>
                    <th> Location </th>
                    <th> Pick Up Date </th>
                    <th> Time </th>
                    <th> Amount </th>
                    <th> Fee </th>
                    <th> Status </th>
                </tr>
            </thead>
            <tbody>
                {
                    list.length == 0 ?
                        <tr>
                            <td colSpan={9}>No data found</td>
                        </tr>
                        :
                        list.map((item, key) => (
                            <tr key={item._id}>
                                <td>
                                    {key + 1}
                                </td>
                                <td>
                                    {item.vehicle.registrationNumber}
                                </td>
                                <td>
                                    {item.package.title}
                                </td>
                                <td>
                                    {item.location?.unit} {item.location?.street} {item.location?.city} ({item.location?.zip})
                                </td>
                                <td>
                                    {moment(item.pickUpDateTime).format('LL')}
                                </td>
                                <td>
                                    {item.time}
                                </td>
                                <td>
                                    {item.amount}
                                </td>
                                <td>
                                    {item.fee}
                                </td>
                                <td>
                                    {item.bookingStatus}
                                </td>
                            </tr>
                        ))
                }
            </tbody>
    </Table>
  )
}

export default BookingTable