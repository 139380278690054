import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getPackageList, getPromoList } from '../../features/PromoSlice/PromoActions'
import Pagination from '../commonComponents/pagination/serverSide'
const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading ] = useState(true)
    const [list,setList] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const promoList = useSelector(state=>state.PromoReducer.list)
    const packageList = useSelector(state=>state.PromoReducer.packageList)
    const [filterData,setFilterData] = useState({
        type:'',
        code:"",
        packageId:""
    })
    const handelFilter = (values) =>{
        setFilterData(values)
		setLoading(true)
        dispatch(getPromoList(1,values))
    }
    const pageChange = (page) => {
        setLoading(true)
        dispatch(getPromoList(page,filterData))
    }
    const getPromoType=(value)=>{
        switch (value) {
            case 0:
                return 'For All'
            case 1:
                return 'For First Time User'
            case 2:
                return 'For Package'
        }
    }
    useEffect(()=>{
        dispatch(getPromoList())
        dispatch(getPackageList())
    },[])
    useEffect(()=>{
        if(Object.keys(promoList).length!=0){
            setList(promoList.docs)
            setPaginationData({
                totalDocs: promoList.totalDocs,
                limit: promoList.limit,
                page: promoList.page,
                totalPages: promoList.totalPages,
                hasPrevPage: promoList.hasPrevPage,
                hasNextPage: promoList.hasNextPage,
                prevPage: promoList.prevPage,
                nextPage: promoList.nextPage
            })
            setLoading(false)
        }
    },[promoList])
    return (
        <div className="page-content">
            <div className="section-title">
                <h4>Promocode</h4>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                            <Formik
                                initialValues={filterData}
                                onSubmit={handelFilter}
                                onReset={handelFilter}
                            >
                                {
                                    formProps=>(
                                        <Form onSubmit={formProps.handleSubmit} onReset={formProps.handleReset}>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Promo Type</Form.Label>
                                                    <Form.Select
                                                        className='form-control'
                                                        name="type"
                                                        value={formProps.values.type}
                                                        onChange={formProps.handleChange}
                                                    >
                                                        <option value=''> Select Promo Type</option>
                                                        <option value={0}> All </option>
                                                        <option value={1}> First Time User </option>
                                                        <option value={2}> Package </option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Promo Code</Form.Label>
                                                    <Form.Control
                                                        name="code"
                                                        value={formProps.values.code}
                                                        onChange={formProps.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className='col-3'>
                                                <Form.Group>
                                                    <Form.Label>Package</Form.Label>
                                                    <Form.Select
                                                        className='form-control'
                                                        name="packageId"
                                                        value={formProps.values.packageId}
                                                        onChange={formProps.handleChange}
                                                    >
                                                        <option> Select Package</option>
                                                        {
                                                            packageList.map(option=>(
                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className="col-3">
                                                <Form.Group>
                                                    <label className='form-label d-block opacity-0 mt-1' htmlFor="phoneFilter">Action</label>
                                                    <button type="submit" className="btn btn-sm btn-success ml-2">Filter</button>
                                                    <button type="reset" className="btn btn-sm btn-info ms-2" >Reset</button>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        </Form>
                                    )
                                }
                                
                            </Formik>
                            
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-12'>
                    <Card>
                        <Card.Header>
                            <div className="row">
                                <div className="col text-end">
                                    <Button
                                        onClick={() => navigate("/admin/promo/add")}
                                        variant="primary"
                                    >
                                        Add Promocode
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered tablespace">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Code</th>
                                                <th scope="col">Discount (%)</th>
                                                <th scope="col">Package</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">CreatedOn</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading?
                                                    <tr>
                                                        <td colSpan={10}>
                                                            Loading....
                                                        </td>
                                                    </tr>
                                                :
                                                    list.length==0?
                                                        <tr>
                                                            <td colSpan={10}>
                                                                No data Found
                                                            </td>
                                                        </tr>
                                                    :
                                                        list.map((item,idx)=>(
                                                            <tr key={item._id}>
                                                                <td>
                                                                    {
                                                                        (
                                                                            (paginationData.page - 1)* paginationData.limit 
                                                                        ) + idx +1
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {getPromoType(item.type)}
                                                                </td>
                                                                <td>
                                                                    {item.code}
                                                                </td>
                                                                <td>
                                                                    {item.percentage}
                                                                </td>
                                                                <td>
                                                                    {item.packageId?item.packageId:"-----"}
                                                                </td>
                                                                <td>
                                                                    {item.startDate}
                                                                </td>
                                                                <td>
                                                                    {item.endDate}
                                                                </td>
                                                                <td>
                                                                    <Badge
                                                                        bg={item.status ? "success" : "danger"}
                                                                    >
                                                                        {item.status ? "Active" : "Deactive"}
                                                                    </Badge>
                                                                </td>
                                                                <td> {moment(item?.createdAt).format('LLLL')} </td>
                                                                <td>
                                                                    <Link to={`/admin/promo/edit/${item._id}`}>
                                                                        <i className="fas fa-edit"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination
                                data={paginationData}
                                pagFun={pageChange}
                            />
                        </Card.Footer>
                    </Card>                    
                </div>
            </div>

        </div>
    )
}

export default Index