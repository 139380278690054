
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUserView } from '../../features/UserSlice/UserActions'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setApiFail } from '../../features/CommonSlice/CommonSlice'
import { Badge, Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'
import VehicleList from './detailComponents/vehicleList'
import BookingList from './detailComponents/bookingList'
const EditUser = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const apiFail = useSelector(state => state.commonReducer.fail)
    const detail = useSelector(state => state.userReducer.userView)
    useEffect(() => {
        if (params.id) {
            dispatch(getUserView({ _id: params.id }))
        }
    }, [params])
    useEffect(() => {
        if (apiFail) {
            toast(apiFail.message, { type: 'error' });
            dispatch(setApiFail(null))
        }
    }, [apiFail])
    return (
        <div className='page-content'>
            {/* {console.log('detail',detail)} */}
            <div className='section-title pb-0'>
                <h4>User Detail</h4>
            </div>
            <div className='row userInfoBG mx-0'>
                <div className='col-2'>
                    <div className='row justify-content-md-center'>
                        <div className='text-center'>
                            <img style={{ width: "180px", height: "180px" }}
                                className='rounded-pill img-thumbnail'
                                src={detail?.profilePicture}
                                alt={detail?.firstName}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-9 d-flex flex-wrap'>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                First Name
                            </div>
                            <div className='col-6'>
                                <b>{detail?.firstName}</b>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                Last Name
                            </div>
                            <div className='col-6'>
                                <b>{detail?.lastName}</b>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                Mobile Number
                            </div>
                            <div className='col-6'>
                                <b>{detail?.mobile}</b>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                Email
                            </div>
                            <div className='col-6'>
                                <b>{detail?.email}</b>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                Gender
                            </div>
                            <div className='col-6'>
                                <b>{detail?.gender}</b>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                Status
                            </div>
                            <div className='col-6'>
                                <Badge
                                    bg={detail?.status ? "success" : "danger"}
                                >
                                    {detail?.status ? "Active" : "Deactive"}

                                </Badge>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-2'>
                        <div className='row'>
                            <div className='col-6'>
                                Created At
                            </div>
                            <div className='col-6'>
                                <b>{detail ? moment(detail.createdAt).format('LLLL') : null}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-5'>
                <div className='col-12'>
                    <Tabs defaultActiveKey="vehicleData" className="mb-3">
                        <Tab eventKey="vehicleData" title="Vehicle">
                            <VehicleList
                                list={detail ? detail.vehicleData : []}
                            />
                        </Tab>
                        <Tab eventKey="bookingData" title="Booking">
                            <BookingList
                                list={detail ? detail.bookingData : []}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
export default EditUser