import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getFaqList } from '../../features/CmsSlice/CmsActions'
import { Api } from '../../services/httpService'
import Pagination from '../commonComponents/pagination/clientSide'
const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const list = useSelector(state=>state.CmsReducer.faqList)
    const [current,setCurrent] = useState({
        offSet:1,
        data:[]
    })
    const getCurrentItem = (data) => {
        setCurrent({
            offSet:data.currentOffset,
            data:data.items
        })
    }
    const deleteFaq = (faqId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This faq will be deleted.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'text-white',
                cancelButton: 'text-white'
            },
        }).then(async (result) => {
          if (result.isConfirmed) {
              try {
                    await Api('POST','/admin/faq/delete',{_id:faqId})
                    document.getElementById(faqId).remove()
                    Swal.fire({
                        icon: 'success',
                        title: 'Faq deleted',
                        showConfirmButton: false,
                        timer: 1500
                    })
              } catch (error) {
                //   console.log('error',error);
                  Swal.fire({
                      icon: 'error',
                      title: error.message,
                      showConfirmButton: false,
                      timer: 1500
                  })
              }
          }
        })
    }
    useEffect(()=>{
        dispatch(getFaqList())
    },[])
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Faq List</h4>
            </div>
            <Card>
                <Card.Header>
                    <div className="row">
                        <div className="col text-end">
                            <Button onClick={() => navigate('/admin/faq/add')} variant="primary">Add Faq</Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='row'>
                        <div className="table-responsive">
                            <table className="table table-bordered tablespace">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.isLoading?
                                            <tr>
                                                <td colSpan={3}>
                                                    Loading.....
                                                </td>
                                            </tr>
                                        :
                                            current.data.length==0?
                                                <tr>
                                                    <td colSpan={3}>
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            :
                                                current.data.map((item,idx)=>(
                                                    <tr id={item._id} key={item._id}>
                                                        <td>
                                                            {current.offSet + (idx+1)}
                                                        </td>
                                                        <td>
                                                            {item.question}
                                                        </td>
                                                        <td>
                                                            <Link to={`/admin/faq/edit/${item._id}`}>
                                                                <i className="fas fa-edit"></i>
                                                            </Link>
                                                            <span 
                                                                role={`button`}
                                                                onClick={()=>deleteFaq(item._id)}
                                                            >
                                                                <i className="mx-2 fas fa-trash"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Pagination
                        itemsPerPage={10}
                        items={list.data}
                        getCurrentItem={getCurrentItem}
                    />
                </Card.Footer>
            </Card>
        </div>
    )
}

export default Index