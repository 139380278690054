import { Api } from "../../services/httpService";
import { apiFail, apiSuccess } from "../CommonSlice/CommonActions";
import { setPackageList, setPackageDetail, setExtraData } from "./PackageSlice";
export const getPackageList = (page=1,filterData={}) => async (dispatch) => {
	try {
		let value = Object.assign({},{page:page},filterData)
		let res = await Api("POST", "/admin/package/list", value);
		if (res.statusCode >= 200 && res.statusCode < 300) {
			dispatch(setPackageList(res.data));
			dispatch(setPackageDetail({
				vehicleType:"",
				city:"",
				title: "",
				description: "",
				price: "",
				status: "",
				zipcodes:[],
				totalWash:"",
				interval:{
					type:'',
					quantity:''
				},
				options:[{
					name:"",
					price:"",
					description:['']
				}]
			}));
		} else {
			dispatch(apiFail(res));
		}
	} catch (error) {
		dispatch(apiFail(error));
	}
};
export const addEditPackage = (value) => async dispatch =>{
	try {
		let res = {}
		if ("_id" in value) {
			res = await Api("POST","/admin/package/update",value)
		}else{
			res = await Api("POST","/admin/package/add",value)
		}
		if (res.statusCode >= 200 && res.statusCode < 300) {
			dispatch(apiSuccess(res));
		} else {
			dispatch(apiFail(res));
		}
	} catch (error) {
		console.log("error", error);
	}
}
export const getPackageDetail = (value) => async dispatch => {
	try {
		let res = await Api("POST","/admin/package/detail",value)
		if (res.statusCode >= 200 && res.statusCode < 300) {
			dispatch(setPackageDetail(res.data));
		} else {
			dispatch(apiFail(res));
		}
	} catch (error) {
		console.log("error", error);
	}
}
export const getExtraData = () => async dispatch =>{
	try {
		let res = await Api("POST","/admin/package/extradata",{})
		if (res.statusCode >=200 && res.statusCode < 300) {
			dispatch(setExtraData(res.data))
		} else {
			dispatch(apiFail(res))
		}
	} catch (error) {
		console.log('error',error);
	}
}