import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useState } from "react";
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown';

//Images
import logo from "../../assets/images/logo.svg";
import hamburgerIcon from "../../assets/images/hamburger.png";
import profileImg from "../../assets/images/profile.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../features/AuthSlice/AuthActions";

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const dispatch = useDispatch()
    const mainNavigation = useRef();
    const hamburger = useRef();
    var authUser = useSelector((state) => state.authReducer.user);
    const openNav = () => {
        setIsNavOpen(true);
    }
    const closeNav = () => {
        setIsNavOpen(false);
    }
    const toggleSidebar = () => {
        document.querySelector('body').classList.toggle('main-sidebar--open');
    }
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (!isNavOpen || mainNavigation.current.contains(e.target) || e.target === hamburger.current) {
                return;
            }
            closeNav();
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return function () {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        }
    }, [isNavOpen]);
    return (
        <header>
            <div className="container">
                <div className="text-start">
                    <button className="mobile-toggler" onClick={openNav}>
                        <img src={hamburgerIcon} alt="" ref={hamburger} />
                    </button>
                    <button className='btn btn-dark sidebar-toggler' onClick={toggleSidebar}>Menu</button>
                </div>
                <Link to="/" className="brand-logo">
                    <img src={logo} alt="logo" />
                    <span>Triumb</span>
                </Link>
                <div className={classNames('main-navigation', isNavOpen && 'show')} ref={mainNavigation}>
                    <nav className="navbar navbar-expand-lg">
                        {/* <ul className="mainmenu">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/">Gallery</Link>
                            </li>
                            <li>
                                <Link to="/">About</Link>
                            </li>
                            <li>
                                <Link to="/">Contact Us</Link>
                            </li>
                        </ul> */}
                        <div className="header-right">
                            <Dropdown className="header-user-dropdown">
                                <Dropdown.Toggle id="dropdown-basic" as="span">
                                    <figure>
                                        <img src={profileImg} alt="" />
                                    </figure>
                                    <div className="user-text">
                                        <h4>{authUser.data?.name}</h4>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu as="ul">
                                    <li>
                                        <Link to='/admin/profile' className="c-dropdown-item">
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <span 
                                            role="button" 
                                            onClick={
                                                ()=>{
                                                    dispatch(logoutAction())
                                                }
                                            } 
                                            className="c-dropdown-item"
                                        >
                                            Logout
                                        </span>
                                    </li>
                                    
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
}

export default Header;