import moment from 'moment'
import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getTaskList } from '../../features/BookingSlice/BookingActions'

const Tasks = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const taskList = useSelector(state=>state.BookingReducer.taskList)
    useEffect(()=>{
        dispatch(getTaskList({bookingId:params.id}))
    },[])
    return (
    <div className='page-content'>
        <div className='section-title'>
            <h4>Booking Tasks </h4>
        </div>
        <Card>
            <Card.Body>
                <div className='row'>
                    <div className='col-12'>
                        <div className="table-responsive">
                            <table className="table table-bordered tablespace">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Status</th>
                                        <th>Create At</th>
                                        <th>Selfie</th>
                                        <th>Task Complete Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        taskList.isLoading?
                                            <tr>
                                                <td colSpan={5}>
                                                    Loading....
                                                </td>
                                            </tr>
                                        :
                                            taskList.data.length==0?
                                                <tr>
                                                    <td colSpan={5}>
                                                        No Data Found
                                                    </td>
                                                </tr>
                                            :
                                                taskList.data.map((item,idx)=>(
                                                    <tr key={item._id}>
                                                        <td>
                                                            {idx+1}
                                                        </td>
                                                        <td>
                                                            {item.status}
                                                        </td>
                                                        <td>
                                                            {moment(item.createdAt).format('LL')}
                                                        </td>
                                                        <td>
                                                            <img src={item.selfie} className="img-thumbnail my-1" alt="Selfie" width="100px" height="100px"/>
                                                        </td>
                                                        <td>
                                                        {item.finishTask.map((img,idx)=>(
                                                            <img src={img} key={idx} alt={`Task Final ${idx}`}width="100px" height="100px" className='img-thumbnail my-1 me-4'/>
                                                        ))}
                                                        </td>
                                                    </tr>
                                                ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </div>
    )
}

export default Tasks