import { configureStore, combineReducers, compose } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import commonSlice from "../features/CommonSlice/CommonSlice";
import authSlice from "../features/AuthSlice/AuthSlice";
import userSlice from "../features/UserSlice/UserSlice";
import DriverSlice from "../features/DriverSlice/DriverSlice";
import SubAdminSlice from "../features/SubAdminSlice/SubAdminSlice";
import BookingSlice from "../features/BookingSlice/BookingSlice";
import SettingSlice from "../features/SettingSlice/SettingSlice";
import PackageSlice from "../features/PackageSlice/PackageSlice";
import CmsSlice from "../features/CmsSlice/CmsSlice";
import PromoSlice from '../features/PromoSlice/PromoSlice'

const rootReducer = combineReducers({
	commonReducer: commonSlice,
	authReducer: authSlice,
	userReducer: userSlice,
	driverReducer: DriverSlice,
	SubAdminReducer: SubAdminSlice,
	BookingReducer: BookingSlice,
	SettingReducer: SettingSlice,
	PackageReducer: PackageSlice,
	CmsReducer: CmsSlice,
	PromoReducer: PromoSlice,
});

const store = configureStore(
	{
		reducer: rootReducer,
		devTools: true,
		middleware: [thunk],
	},
	(window.__REDUX_DEVTOOLS_EXTENSION__ &&
		window.__REDUX_DEVTOOLS_EXTENSION__()) ||
		compose
);

export default store;
