import React, { useEffect, useState } from 'react';
import { loginAction } from "../../features/AuthSlice/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.svg";
import { Alert, Form } from 'react-bootstrap';
import { setAuthFailed } from '../../features/AuthSlice/AuthSlice';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const Login = () => {
    const dispatch = useDispatch();
    const authError = useSelector(state=>state.authReducer.errorMessage)
    const [passToggal,setPassToggel] = useState(false)
    const validationSchema = Yup.object().shape({
        email:Yup.string().required("Email is required").email("Email not valid"),
        password:Yup.string().required("Password is required")
    })
    const toggalPass = () =>{
        setPassToggel(!passToggal)
    }
    const loginSubmit = (values) => {
        dispatch(loginAction(values));
    };
    useEffect(() => {
        if (authError!=='') {
            setTimeout(()=>{
                dispatch(setAuthFailed(''))
            },2000)
        }
    }, [authError])
    
    return (
        <div className="login-wrapper">
            {/* {console.log('authError',authError)} */}
            <div className="container">
                <div className="row form-wrapper d-flex justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                        <div className="form-border-box">
                            <div className='form-logo'>
                                <img src={logo} alt="" />
                            </div>
                            <div className="form-title">
                                <h4>Triumb Login</h4>
                                <p>Please enter your details below to continue</p>
                            </div>
                            {
                                authError!==''?
                                    <Alert key='danger' variant='danger'>
                                        {authError}
                                    </Alert>
                                :
                                    null
                            }
                            <Formik
                                initialValues={{email:"",password:""}}
                                validationSchema={validationSchema}
                                onSubmit={loginSubmit}
                            >
                                {formProps=>(
                                    <Form onSubmit={formProps.handleSubmit}>
                                        <Form.Group className='my-3'>
                                            {/* <Form.Label>Email</Form.Label> */}
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Email address" 
                                                id="email" 
                                                value={formProps.values.email} 
                                                name="email" 
                                                autoComplete='off' 
                                                onChange={formProps.handleChange}
                                                isInvalid={formProps.touched.email && formProps.errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formProps.errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='my-3'>
                                            {/* <Form.Label>Password</Form.Label> */}
                                            <div className='passwordField'>
                                                <Form.Control 
                                                    type={ passToggal?"text":"password" }
                                                    placeholder="Password" 
                                                    id="password" 
                                                    value={formProps.values.password} 
                                                    name="password" 
                                                    autoComplete='off' 
                                                    onChange={formProps.handleChange}
                                                    isInvalid={formProps.touched.password && formProps.errors.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {formProps.errors.password}
                                                </Form.Control.Feedback>
                                                {
                                                    passToggal?
                                                        <FontAwesomeIcon icon={faEye} onClick={toggalPass} />
                                                    :
                                                        <FontAwesomeIcon icon={faEyeSlash} onClick={toggalPass} />
                                                }
                                            </div>
                                            
                                        </Form.Group>
                                        <div className='form-submit-btn'>
                                            <button type="submit" className="button button-black w-100">Login</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
