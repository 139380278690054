import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Api } from '../../services/httpService'

const View = () => {
    const params = useParams()
    const [pageData,setPageData] = useState({
        title:"",
        image:"",
        content:""
    })
    const getCmsPageData = async ()=>{ 
        try {
            let res = await Api("POST","/admin/pageload",{slug:params.slug})
            if (res.statusCode>=200 && res.statusCode < 300) {
                setPageData(res.data)
            }else{
                console.log('page error',res.message);
            }
        } catch (error) {
            console.log('error',error.message);
        }       
        
    }
    useEffect(()=>{
        getCmsPageData()
    },[])
    return (
        <div className='page-content'>
            <div className='section-title pb-0'>
                <h4>{pageData.title}</h4>
            </div>
            <div className='row'>
                {
                    pageData.image!==''?
                        <div className='col-12 mb-3 text-center'>
                            <img src={pageData.image} alt={pageData.title}/>
                        </div>
                    :
                        null
                }
                <div className='col-12'
                    dangerouslySetInnerHTML={{
                        __html:pageData.content
                    }}
                />
            </div>
        </div>
    )
}

export default View