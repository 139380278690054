import { createSlice } from '@reduxjs/toolkit';


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userList: {},
        userEdit:{
            firstName:"",
            lastName:"",
            email:"",
            mobile:"",
            gender:"",
            status:""   
        },
        userView:null
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action;
        },
        setUserList: (state, action) => {
            state.userList = action.payload;
        },
        // setError: (state, action) => {
        //     state.errorMessage = action;
        // },
        setUserEdit:(state,action)=>{
            state.userEdit = action.payload
        },
        setUserView:(state,action)=>{
            state.userView = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
// setError,
export const { setUserList, setLoading, setUserEdit, setUserView } = userSlice.actions

export default userSlice.reducer
