import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { setApiFail, setApiSuccess } from "../../features/CommonSlice/CommonSlice";
import { addEditPackage, getExtraData, getPackageDetail } from "../../features/PackageSlice/PackageActions";
import Select from 'react-select/creatable'
const PackageAddEdit = () => {
	const dispatch = useDispatch();
	const params = useParams()
	const navigate = useNavigate();
	const validationSchema = Yup.object().shape({
		city: Yup.string().required("City is required"),
		vehicleType: Yup.string().required("Vehicle type is required"),
		title: Yup.string().required("Title is required"),
		price: Yup.string().required("Price is required"),
		description: Yup.string().required("Description is required"),
		status: Yup.boolean().required("Status is required"),
		totalWash:Yup.number().typeError("Must be a number").required("Total Wash is required").positive("Must be a positive number").integer("Must be integer").min(1,"Must be greater than one"),
		zipcodes:Yup.array().min(1,"Atleast enter one zipcode"),
		interval:Yup.object().shape({
			type:Yup.string().required('Type is required'),
			quantity:Yup.number().typeError("Quantity must be a number")
				.required('Quantity is required')
				.test("quantity",'',(value,fields)=>{
					if (fields.parent?.type==0 && value > 12) {
						return fields.createError({message:"Quantity not greater than 12"})
					}else if(fields.parent?.type==1 && value > 365){
						return fields.createError({message:"Quantity not greater than 365"})
					}else{
						return true
					}
				})
		}),
		options:Yup.array().of(
			Yup.object().shape({
				name:Yup.string().required('Option name is required'),
				price:Yup.number().typeError("Must be a number").required("Option Price is required"),
				description:Yup.array().of(Yup.string().required('Description required')).min(1,"Atleast one description required")
			})
		).min(1,"Atleast one Option required")
	});
	const packageDetail = useSelector(state=>state.PackageReducer.packageDetail)
	const packageExtraData = useSelector(state=>state.PackageReducer.packageExtraData)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
	const apiFail = useSelector(state=>state.commonReducer.fail)
	const handelFormSubmit = (values) => {
		let finalData = {...values}
		finalData.zipcodes = finalData.zipcodes.map(val=>val.value)
		dispatch(addEditPackage(finalData))
	};
	const addOptions = (options,setFieldValue) => {
		let tempOptions = [...options];
		tempOptions.push({name:"",price:"",description:['']})
		setFieldValue('options',tempOptions)
	}
	const removeOptions = (index,options,setFieldValue,setFieldTouched) =>{
		let tempOptions = [...options];
		tempOptions.splice(index,1)
		setFieldValue('options',tempOptions)
		setFieldTouched(`options.${tempOptions.length}`,false,false)
	}
	const addOptionDescription = (index,value,setFieldValue) =>{
		let tempDescription = [...value]
		tempDescription.push('')
		setFieldValue(`options.${index}.description`,tempDescription)
	}
	const removeDescriptionPoints = (index,parentIndex,value,setFieldValue) =>{
		let tempDescription = [...value]
		tempDescription.splice(index,1)
		setFieldValue(`options.${parentIndex}.description`,tempDescription)
	}
	useEffect(()=>{
		dispatch(getExtraData())
	},[])
	useEffect(()=>{
		if (params.id) {
			dispatch(getPackageDetail({_id:params.id}))	
		}
	},[params])
	useEffect(()=>{
		if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
			navigate('/admin/package')
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
	},[apiSuccess,apiFail])
	return (
		<div className="page-content">
			<div className="section-title">
				<h4>{('_id' in packageDetail)? 'Edit' : 'Add' } Package</h4>
			</div>
			<Formik
				enableReinitialize
				initialValues={packageDetail}
				validationSchema={validationSchema}
				onSubmit={handelFormSubmit}
			>
				{(formProps) => (
					<Form onSubmit={formProps.handleSubmit}>
						{/* {console.log(formProps.errors)} */}
						<div className="row">
							<div className="col-md-6 mt-2">
								<Form.Group controlId="interval.type">
									<Form.Label>Package City *</Form.Label>
									<Form.Select
										type="text"
										className="form-control"
										placeholder="Select Package City"
										name="city"
										value={formProps.values.city}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										disabled={formProps.values._id?true:false}
										isInvalid={
											formProps.touched.city && formProps.errors.city
										}
									>
										<option value=''>Select Package City</option>
										{
											packageExtraData.city.map(option=>(
												<option key={option.value} value={option.value}>{option.label}</option>
											))
										}
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.city}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6 mt-2">
								<Form.Group controlId="interval.type">
									<Form.Label>Package Vehicle Type *</Form.Label>
									<Form.Select
										type="text"
										className="form-control"
										placeholder="Select Package Vehicle Type"
										name="vehicleType"
										value={formProps.values.vehicleType}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										disabled={formProps.values._id?true:false}
										isInvalid={
											formProps.touched.vehicleType && formProps.errors.vehicleType
										}
									>
										<option value=''>Select Vehicle Interval</option>
										{
											packageExtraData.vehicleType.map(option=>(
												<option key={option.value} value={option.value}>{option.label}</option>
											))
										}
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.vehicleType}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6 mt-2">
								<Form.Group controlId="title">
									<Form.Label>Title *</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Title"
										name="title"
										value={formProps.values.title}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										isInvalid={
											formProps.touched.title && formProps.errors.title
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.title}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-6 mt-2">
								<Form.Group controlId="Price">
									<Form.Label>Price *</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Price"
										name="price"
										value={formProps.values.price}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										isInvalid={
											formProps.touched.price && formProps.errors.price
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.price}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-4 mt-2">
								<Form.Group controlId="totalWash">
									<Form.Label>Total Wash *</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Total Wash"
										name="totalWash"
										value={formProps.values.totalWash}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										isInvalid={
											formProps.touched.totalWash && formProps.errors.totalWash
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.totalWash}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-4 mt-2">
								<Form.Group controlId="interval.type">
									<Form.Label>Package Interval Type *</Form.Label>
									<Form.Select
										type="text"
										className="form-control"
										placeholder="Enter Package Interval Type"
										name="interval.type"
										value={formProps.values.interval?.type}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										disabled={formProps.values._id?true:false}
										isInvalid={
											formProps.touched.interval?.type && formProps.errors.interval?.type
										}
									>
										<option value=''>Select Package Interval</option>
										<option value={0}>Monthly</option>
										<option value={1}>Daily</option>
									</Form.Select>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.interval?.type}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-4 mt-2">
								<Form.Group controlId="interval.quantity">
									<Form.Label>Package Interval Quantity *</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Package Interval Quantity"
										name="interval.quantity"
										value={formProps.values.interval.quantity}
										onChange={formProps.handleChange}
										disabled={formProps.values._id?true:false}
										onBlur={formProps.handleBlur}
										isInvalid={
											formProps.touched.interval?.quantity && formProps.errors.interval?.quantity
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.interval?.quantity}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className="col-md-12 mt-2">
								<Form.Group controlId="Price">
									<Form.Label>Description *</Form.Label>
									<Form.Control
										type="textarea"
										placeholder="Enter Description"
										name="description"
										value={formProps.values.description}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										isInvalid={
											formProps.touched.description && formProps.errors.description
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.description}
									</Form.Control.Feedback>
								</Form.Group>
							</div>
							<div className='col-md-12 mt-2'>
                              <Form.Group controlId="zipcodes">
                                <Form.Label>Zipcode * (Available service area)</Form.Label>
								{
									(packageDetail.zipcodes.length!==0)?
										<>
											<Select
												isMulti
												onChange={(newValue)=>{
													formProps.setFieldValue('zipcodes',newValue)
												}}
												defaultValue={ packageDetail.zipcodes }
												options={ formProps.values.selectOption }
											/>
										</>
									:
										<Select
											isMulti
											onChange={(newValue)=>{
												formProps.setFieldValue('zipcodes',newValue)
											}}
											options={ formProps.values.selectOption }
										/>
								}
                                {
									formProps.touched.zipcodes && formProps.errors.zipcodes?
									<div className="validation-error-custome">{formProps.errors.zipcodes}</div>
									:''
                              	}
                              </Form.Group>
                              
                            </div>
							<div className="col-md-12 mt-2">
								<Form.Group controlId="status">
									<Form.Label>Status *</Form.Label>
									<div key={`inline-radio`} className="mb-3">
										{
											packageDetail.status !==""?
												<>
													<Form.Check
														inline
														defaultChecked={packageDetail.status?true:false}
														id={`inline-radio-1`}
														label="Active"
														type="radio"
														name="status"
														value={1}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
													/>
													<Form.Check
														inline
														defaultChecked={!packageDetail.status?true:false}
														id={`inline-radio-2`}
														label="Deactive"
														type="radio"
														name="status"
														value={0}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
													/>
												</>
											:
												<>
													<Form.Check
														inline
														id={`inline-radio-1`}
														label="Active"
														type="radio"
														name="status"
														value={1}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
													/>
													<Form.Check
														inline
														id={`inline-radio-2`}
														label="Deactive"
														type="radio"
														name="status"
														value={0}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
													/>
												</>
										}										
									{formProps.touched.status && formProps.errors.status ? (
										<div className="validation-error-custome">{formProps.errors.status}</div>
									) : null}
									</div>
								</Form.Group>
							</div>
							<div className="col-md-12 mt-2">
								<Card>
									<Card.Header>
									<div className="row">
										<div className="col-md-6">
											<h4> Option's </h4>
										</div>
										<div className="col-md-6">
											<button type="button" onClick={()=>addOptions(formProps.values.options,formProps.setFieldValue)} className="btn btn-success float-end">Add Options</button>
										</div>
									</div>
									</Card.Header>
									<Card.Body>
										
										<FieldArray name="options">
										{
											()=>(
												formProps.values.options.map((option,key)=>{
													const optionErrors = formProps.errors.options?.length && formProps.errors.options[key] || {};
													const optionTouched = formProps.touched.options?.length && formProps.touched.options[key] || {};
													const optionValue = formProps.values.options?.length && formProps.values.options[key] || {};
													return (
														<Card className="mt-2" key={key}>
															<Card.Header>
																<h4 className="d-inline">
																	Option {key+1}
																</h4>
																<button type="button" onClick={()=>removeOptions(key,formProps.values.options,formProps.setFieldValue,formProps.setFieldTouched)} className="btn btn-danger float-end">
																	<i className="fa fa-minus-circle"></i>
																</button>
															</Card.Header>
															<Card.Body>
																<div className="row mt-2">
																	<div className="col-md-6">
																		<Form.Group controlId={`name.${key}`}>
																			<Form.Label>Name</Form.Label>
																			<Form.Control
																				type="text"
																				placeholder="Enter Title"
																				value={optionValue.name}
																				name={`options.${key}.name`}
																				onChange={formProps.handleChange}
																				onBlur={formProps.handleBlur}
																				isInvalid={
																					optionTouched.name && optionErrors.name
																				}
																			/>
																			<Form.Control.Feedback type="invalid">
																				{optionErrors.name}
																			</Form.Control.Feedback>
																		</Form.Group>
																	</div>
																	<div className="col-md-6">
																		<Form.Group controlId="{`price.${key}`}">
																			<Form.Label>Price</Form.Label>
																			<Form.Control
																				type="text"
																				placeholder="Enter Price"
																				name={`options.${key}.price`}
																				value={optionValue.price}
																				onChange={formProps.handleChange}
																				onBlur={formProps.handleBlur}
																				isInvalid={
																					optionTouched.price && optionErrors.price
																				}
																			/>
																			<Form.Control.Feedback type="invalid">
																				{optionErrors.price}
																			</Form.Control.Feedback>
																		</Form.Group>
																	</div>
																	<div className="col-md-12">
																		<Card className="mt-1">
																			<Card.Header>
																				<h4 className="d-inline">
																					Description
																				</h4>
																				<button 
																					type="button" 
																					onClick={
																						()=>addOptionDescription(key,formProps.values.options[key]['description'],formProps.setFieldValue)
																					} 
																					className="btn btn-success float-end"
																				>
																					<i className="fa fa-plus-circle"></i>
																				</button>
																			</Card.Header>
																			<Card.Body>
																				<FieldArray name={`options.${key}.description`}>
																					{
																						()=>(
																							formProps.values.options[key]['description'].map((des,i)=>{
																								return (
																									<div className="row mt-1" key={`${key}.${i}`}>
																										<div className="col-md-11">
																											<Form.Group 
																												controlId={`description.${key}.${i}`}
																											>
																												<Form.Label>Description Point {i+1}</Form.Label>
																												<Form.Control
																													type="text"
																													placeholder="Enter Description"
																													value={optionValue.description[i]}
																													name={`options.${key}.description[${i}]`}
																													onChange={formProps.handleChange}
																													onBlur={formProps.handleBlur}
																													isInvalid={
																														optionTouched.description && optionErrors.description && typeof optionErrors.description !== "string" && optionErrors.description[i]
																													}
																												/>
																												<Form.Control.Feedback type="invalid">
																													{optionErrors.description && optionErrors.description[i]}
																												</Form.Control.Feedback>
																											</Form.Group>
																										</div>	
																										<div className="col-md-1">
																											<Form.Label className="opacity-0 m-0">Description Point {i+1}</Form.Label>
																											<button 
																												type="button" 
																												onClick={()=>removeDescriptionPoints(i,key,formProps.values.options[key]['description'],formProps.setFieldValue)} 
																												className="btn btn-danger float-end"
																											>
																												<i className="fa fa-minus-circle"></i>
																											</button>
																										</div>
																									</div>
																								)
																							})
																						)
																					}
																				</FieldArray>
																				{
																					optionTouched.description && optionErrors.description && typeof optionErrors.description === "string" ? (
																						<div>{optionErrors.description}</div>
																					) : null
																				}
																			</Card.Body>
																		</Card>
																	</div>
																</div>
															</Card.Body>

														</Card>
														
													)
												})
											)
										}
										</FieldArray>
										{
											formProps.touched.options && typeof formProps.errors.options && typeof formProps.errors.options ==='string' ?
												<div>{formProps.errors.options}</div>
											:
												null 
										}
									</Card.Body>
								</Card>
							</div>
							{/* <div className="col-md-6 mt-2">
								<Form.Group controlId="mobile">
									<Form.Label>Option</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter Phone Number"
										name="mobile"
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
										isInvalid={
											formProps.touched.mobile && formProps.errors.mobile
										}
									/>
									<Form.Control.Feedback type="invalid">
										{formProps.errors.mobile}
									</Form.Control.Feedback>
								</Form.Group>
							</div> */}
							
							<div className="col-md-12 mt-2">
								<Button variant="primary" type="submit">
									Submit
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
export default PackageAddEdit;
