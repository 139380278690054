import { Api } from "../../services/httpService";
import { logoutAction } from "../AuthSlice/AuthActions";
import { setApiSuccess, setApiFail, setBookingCount,setBookingChart, setUserChart, setPaymentChart, setRefundChart } from "./CommonSlice";


export const apiSuccess = (value) => async dispatch => {
    dispatch(setApiSuccess(value))
}
export const apiFail = (value) => async dispatch => {
    if (value.statusCode==401 || value.statusCode==403) {
        dispatch(logoutAction(value))        
    }
    dispatch(setApiFail(value))
}
export const getDasboardCount = () => async dispatch => {
    try{
        let res = await Api("POST",'/admin/dashboardCount',{})
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setBookingCount(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getBookingChart = (value) => async dispatch => {
    try {
        let res = await Api("POST","/admin/booking-chart",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setBookingChart(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error",error);
    }
}
export const getUserChart = (value) => async dispatch => {
    try {
        let res = await Api("POST","/admin/user-chart",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setUserChart(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error",error);
    }
}
export const getPaymentChart = (value) => async dispatch => {
    try {
        let res = await Api("POST","/admin/payment-chart",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setPaymentChart(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error",error);
    }
}
export const getRefundChart = (value) => async dispatch => {
    try {
        let res = await Api("POST","/admin/refund-chart",value)
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setRefundChart(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error",error);
    }
}