import { FieldArray, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
import { getSettingList, getTimeSlots, updateTimeSlot } from '../../features/SettingSlice/SettingActions'
const Index = () => {
    const dispatch = useDispatch()
    const settingList = useSelector(state=>state.SettingReducer.list)
    const timeSlotsList = useSelector(state=>state.SettingReducer.timeSlotsList)
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const [timeSlot, setTimeSlot] = useState([])
    const handelFromSubmit = (values) => {
        let submitData = {
            _id: values._id,
            monday:[],
            tuesday:[],
            wednesday:[],
            thursday:[],
            friday:[],
            saturday:[],
            sunday:[]
        }
        let checkboxChecked = document.querySelectorAll(`input[type="checkbox"]:checked`)
        checkboxChecked.forEach(item=>{
            submitData[item.getAttribute('name')].push(item.value)
        })
        dispatch(updateTimeSlot(submitData))
    }
    useEffect(()=>{
        dispatch(getSettingList())
        dispatch(getTimeSlots())
    },[])
    useEffect(() => {
        if (settingList.openTime!=="" && settingList.closeTime!=="" && settingList.bookingIntDur) {
            let tempSlot = []
            var startTime = moment(settingList.openTime, 'HH:mm');
            var endTime = moment(settingList.closeTime, 'HH:mm');
            while(startTime <= endTime){
                let tempSTime = startTime.clone()
                startTime.add(settingList.bookingIntDur, 'minutes');
                tempSlot.push(`${new moment(tempSTime).format('HH:mm')} - ${new moment(startTime).format('HH:mm')}`);
            }
            // remove last Value from timeSlots
            tempSlot.pop()
            setTimeSlot(tempSlot)
        }
    }, [settingList])
    
    useEffect(()=>{
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    },[apiSuccess,apiFail])
  return (
    <div className='page-content'>
        <div className='section-title'>
            <h4>Setting</h4>
        </div>
        <div className='row'>
            <div className='col-12'>
                <Formik
                    enableReinitialize
                    initialValues={timeSlotsList}
                    onSubmit={handelFromSubmit}
                >
                {
                    formProps=>(
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Monday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.monday.includes(item)?
                                            
                                                <Form.Check
                                                    inline
                                                    defaultChecked
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`monday`}
                                                    type="checkbox"
                                                    key={`monday-${key}`}
                                                    id={`monday-${key}`}
                                                />
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`monday`}
                                                    type="checkbox"
                                                    key={`monday-${key}`}
                                                    id={`monday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Tuesday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.tuesday.includes(item)?
                                                
                                                    <Form.Check
                                                        inline
                                                        defaultChecked
                                                        label={item}
                                                        value={item}
                                                        onChange={formProps.handleChange}
                                                        name={`tuesday`}
                                                        type="checkbox"
                                                        key={`tuesday-${key}`}
                                                        id={`tuesday-${key}`}
                                                    />
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`tuesday`}
                                                    type="checkbox"
                                                    key={`tuesday-${key}`}
                                                    id={`tuesday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Wednesday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.wednesday.includes(item)?
                                                
                                                    <Form.Check
                                                        inline
                                                        defaultChecked
                                                        label={item}
                                                        value={item}
                                                        onChange={formProps.handleChange}
                                                        name={`wednesday`}
                                                        type="checkbox"
                                                        key={`wednesday-${key}`}
                                                        id={`wednesday-${key}`}
                                                    />
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`wednesday`}
                                                    type="checkbox"
                                                    key={`wednesday-${key}`}
                                                    id={`wednesday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Thursday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.thursday.includes(item)?
                                                
                                                    <Form.Check
                                                        inline
                                                        defaultChecked
                                                        label={item}
                                                        value={item}
                                                        onChange={formProps.handleChange}
                                                        name={`thursday`}
                                                        type="checkbox"
                                                        key={`thursday-${key}`}
                                                        id={`thursday-${key}`}
                                                    />
                                                
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`thursday`}
                                                    type="checkbox"
                                                    key={`thursday-${key}`}
                                                    id={`thursday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Friday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.friday.includes(item)?
                                                
                                                    <Form.Check
                                                        inline
                                                        defaultChecked
                                                        label={item}
                                                        value={item}
                                                        onChange={formProps.handleChange}
                                                        name={`friday`}
                                                        type="checkbox"
                                                        key={`friday-${key}`}
                                                        id={`friday-${key}`}
                                                    />
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`friday`}
                                                    type="checkbox"
                                                    key={`friday-${key}`}
                                                    id={`friday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Saturday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.saturday.includes(item)?
                                                <Form.Check
                                                    inline
                                                    defaultChecked
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`saturday`}
                                                    type="checkbox"
                                                    key={`saturday-${key}`}
                                                    id={`saturday-${key}`}
                                                />
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`saturday`}
                                                    type="checkbox"
                                                    key={`saturday-${key}`}
                                                    id={`saturday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                <Form.Group controlId="">
                                    <Form.Label>Sunday</Form.Label>
                                    <div className="mb-3">
                                    {
                                        timeSlot.map((item,key)=>(
                                            formProps.values.sunday.includes(item)?
                                                    <Form.Check
                                                        inline
                                                        defaultChecked
                                                        label={item}
                                                        value={item}
                                                        onChange={formProps.handleChange}
                                                        name={`sunday`}
                                                        type="checkbox"
                                                        key={`sunday-${key}`}
                                                        id={`sunday-${key}`}
                                                    />
                                            :
                                                <Form.Check
                                                    inline
                                                    label={item}
                                                    value={item}
                                                    onChange={formProps.handleChange}
                                                    name={`sunday`}
                                                    type="checkbox"
                                                    key={`sunday-${key}`}
                                                    id={`sunday-${key}`}
                                                />
                                        ))
                                    }
                                    </div>
                                </Form.Group>
                                </div>
                                <div className='col-md-12 mt-2'>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }
                </Formik>
            </div>
        </div>
    </div>
  )
}

export default Index