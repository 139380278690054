import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import CKEdit from '../commonComponents/from/ckeditor'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
import { toast } from 'react-toastify'
import { addFaq } from '../../features/CmsSlice/CmsActions'
const Add = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const validationSchema = Yup.object().shape({
        question:Yup.string().required("Title is required"),
        answer:Yup.string().required('Answer is required')
    })
    const handelSubmit = (values) =>{
        dispatch(addFaq(values))
    }
    useEffect(() => {
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
            navigate('/admin/faq')
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    }, [apiSuccess,apiFail]) 
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Add Faq</h4>
            </div>
            <Formik
                initialValues={{
                    question:"",
                    answer:''
                }}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
            >
                {formProps=>(
                    <Form onSubmit={formProps.handleSubmit}>
                        <div className='row'>
                            <div className="col-12 mb-2">
                                <Form.Group>
                                    <Form.Label>Question *</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='question'
                                        placeholder='Enter Question'
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        isInvalid={formProps.touched.question && formProps.errors.question}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formProps.errors.question}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col-12 mb-2">
                                <Form.Group>
                                    <Form.Label>Answer *</Form.Label>
                                    <CKEdit
                                        name="answer"
                                        getValue={formProps.setFieldValue}
                                        defValue={formProps.values.answer}
                                        error={formProps.errors.answer}
                                        errorStatus={formProps.touched.answer && formProps.errors.answer}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Add