import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { cmsPageUpdate, getCmsPageDetail } from '../../features/CmsSlice/CmsActions'
import * as Yup from 'yup'
import CKEdit from '../commonComponents/from/ckeditor'
import { toast } from 'react-toastify'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
const Edit = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const detail = useSelector(state=>state.CmsReducer.cmsPageDetail)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const validationSchema = Yup.object().shape({
        title:Yup.string().required("Title is required")
    })
    const [ img , setImg ] = useState({
        pre:null,
        value:null
    })
    const handelFileChange = (e) =>{
        setImg({
            pre:URL.createObjectURL(e.target.files[0]),
            value:e.target.files[0]
        })
    }
    const handelSubmit = (values) =>{
        let formData = new FormData()
        Object.keys(values).forEach(key=>{
            formData.append(key,values[key])
        })
        if(img.value){
            formData.append('image',img.value)
        }
        dispatch(cmsPageUpdate(formData))
    }
    useEffect(()=>{
        dispatch(getCmsPageDetail({slug:params.slug}))
    },[])
    useEffect(() => {
        if (apiSuccess) {
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
            navigate('/admin/cms-page')
        }
        if (apiFail) {
            toast(apiFail.message,{ type:'error' });
            dispatch(setApiFail(null))
        }
    }, [apiSuccess,apiFail]) 
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>Cms Page List</h4>
            </div>
            <Formik
                enableReinitialize
                initialValues={detail.data}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
            >
                {formProps=>(
                    <Form onSubmit={formProps.handleSubmit}>
                        <div className='row'>
                            <div className='col-6 mb-2'>
                                <Form.Group>
                                    <Form.Label>Slug</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type='text'
                                        placeholder='Enter Page Slug'
                                        name="slug"
                                        value={formProps.values.slug}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                    />
                                </Form.Group>
                            </div>
                            <div className='col-6 mb-2'>
                                <Form.Group>
                                    <Form.Label>Title *</Form.Label>
                                    <Form.Control
                                        type='text'
                                        placeholder='Enter Page Title'
                                        name="title"
                                        value={formProps.values.title}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        isInvalid={formProps.touched.title && formProps.errors.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formProps.errors.title}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className='col-md-8 mt-2'>
                                <Form.Group controlId="gender">
                                    <Form.Label>Profile</Form.Label>
                                    <Form.Control type="file" onChange={handelFileChange} />
                                </Form.Group>
                            </div>
                            <div className='col-md-4 mt-2'>
                                <img width={150} height={150} src={img.pre?img.pre:detail.data.image}/>
                            </div>
                            <div className='col-12 mb-2'>
                                <Form.Group>
                                    <Form.Label>Content</Form.Label>
                                    {
                                        (!detail.isLoading)?
                                            <CKEdit
                                                name="content"
                                                getValue={formProps.setFieldValue}
                                                defValue={detail.data.content}
                                                error={formProps.errors.content}
                                                errorStatus={formProps.touched.content && formProps.errors.content}
                                            />
                                        :
                                            <p>Loading .....</p>
                                    }

                                </Form.Group>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                    
                )}
            </Formik>
        </div>
    )
}

export default Edit