
import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setError, setUserList, setLoading,setUserEdit,setUserView } from "./UserSlice";

export const getUserList = (page=1,filterData={}) => async (dispatch) => {
    try {
        let value = Object.assign({},{page:page},filterData)
        let res = await Api('POST', `/admin/user-list`, value);
        console.log("res", res);
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setUserList(res.data));
            dispatch(setUserEdit({ firstName:"", lastName:"", email:"", mobile:"", gender:"", status:"" }))
        }else{
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
} 
export const createUser = (value) => async dispatch =>{
    dispatch(setLoading(true));
    try {
        let res = await Api('POST', `/admin/user-add`, value,true);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
            dispatch(getUserList())
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
    

}
export const updateUser = (value) => async dispatch =>{
    dispatch(setLoading(true));
    try {
        let res = await Api('POST', `/admin/user-update`, value,true);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(apiSuccess(res))
            dispatch(getUserList())
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
    

}
export const getUserEdit = (value) => async dispatch => {
    try {
        let res = await Api('POST', `/admin/user-edit-detail`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setUserEdit(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}
export const getUserView = (value) => async dispatch => {
    try {
        let res = await Api('POST', `/admin/user-view`, value);    
        if (res.statusCode >= 200 && res.statusCode < 300 ) {
            dispatch(setUserView(res.data))
        }else{    
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log("error", error);
    }
}