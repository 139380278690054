import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../../components/auth/Login"
import CmsView from '../../components/cmsPage/view'
const Public = (props) => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/page/:slug" element={<CmsView/>}/>
        <Route
            path="*"
            element={<Navigate to="/" replace />}
        />
      </Routes>
    </div>
  )
}

export default Public;