import React, { useEffect, useState } from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { getCityList } from '../../features/CmsSlice/CmsActions'
import Pagination from '../commonComponents/pagination/clientSide'
const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const list = useSelector(state=>state.CmsReducer.cityList)
    const [current,setCurrent] = useState({
        offSet:1,
        data:[]
    })
    const getCurrentItem = (data) => {
        setCurrent({
            offSet:data.currentOffset,
            data:data.items
        })
    }
    useEffect(()=>{
        dispatch(getCityList())
    },[])
    return (
        <div className='page-content'>
            <div className='section-title'>
                <h4>
                    City List
                </h4>
            </div>
            <Card>
                <Card.Header>
                    <div className="row">
                        <div className="col text-end">
                            <Button onClick={() => navigate('/admin/city/add')} variant="primary">Add City</Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="table-responsive">
                                <table className="table table-bordered tablespace">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.isLoading?
                                                <tr>
                                                    <td colSpan={4}>
                                                        Loading....
                                                    </td>
                                                </tr>
                                            :
                                                current.data.length==0?
                                                    <tr>
                                                        <td colSpan={4}>
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                :
                                                    current.data.map((item,idx)=>(
                                                        <tr key={item._id}>
                                                            <td> {current.offSet + (idx+1)} </td>
                                                            <td> {item.title} </td>
                                                            <td> 
                                                                <Badge
                                                                    bg={item.status ? "success" : "danger"}
                                                                >
                                                                    {item.status ? "Active" : "Deactive"}
                                                                </Badge>
                                                            </td>
                                                            <td>
                                                                <Link to={`/admin/city/edit/${item._id}`}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Pagination
                        itemsPerPage={10}
                        items={list.data}
                        getCurrentItem={getCurrentItem}
                    />
                </Card.Footer>
            </Card>
        </div>
    )
}

export default Index