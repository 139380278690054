import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { assignDriverToBooking, getAvailableDriver, getBookingList } from '../../../features/BookingSlice/BookingActions'
import { unSetAvailableDriverList } from '../../../features/BookingSlice/BookingSlice'
import { setApiFail, setApiSuccess } from '../../../features/CommonSlice/CommonSlice'

const DriverAssignModel = ({status,bookingId,handelAssignDriverModel}) => {
    const dispatch = useDispatch()
    const driverList = useSelector(state=>state.BookingReducer.availableDriverList)
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const [alert,setAlert] = useState({
        status:false,
        message:""
    })
    const handelSubmit = (values) =>{
        dispatch(assignDriverToBooking(values))
    }
    useEffect(()=>{
        if (status) {
            dispatch(getAvailableDriver({_id:bookingId}))
        }else{
            dispatch(unSetAvailableDriverList())
        }
    },[status])
    useEffect(()=>{
        if(apiSuccess){
            toast(apiSuccess.message,{ type:'success' });
            dispatch(setApiSuccess(null))
            handelAssignDriverModel()
            dispatch(getBookingList())
        }
        if (apiFail) {
            setAlert({
                status:true,
                message:apiFail.message
            })
            setTimeout(() => {
                dispatch(setApiFail(null))
                setAlert({
                    status:false,
                    message:""
                })  
            }, 2000);
        }
    },[apiFail,apiSuccess])
    return (
        <Modal show={status} onHide={()=>handelAssignDriverModel(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Driver</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    _id:bookingId,
                    driverId:""
                }}
                onSubmit={handelSubmit}
            >
                {
                    formProps=>(
                    <Form onSubmit={formProps.handleSubmit}>
                        {
                            alert.status?
                                <Alert variant='danger'>
                                    {alert.message}
                                </Alert>
                            :null
                        }
                        <Modal.Body>
                            {
                                driverList.isLoading?
                                    <div>Loading....</div>
                                :
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Available Driver List</Form.Label>
                                        <Form.Select 
                                            name="driverId" 
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                        >
                                            <option value="">Select Assign Driver</option>
                                            {
                                                driverList.data.map(item=>(
                                                    <option key={item._id} value={ item._id }>{ item.name }</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                            }
                        </Modal.Body>
                        {
                            !driverList.isLoading?
                                <Modal.Footer>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            :
                                null
                        }
                    </Form>
                    )
                }
                
            </Formik>
        </Modal>
    )
}

export default DriverAssignModel