import { Api } from '../../services/httpService';
import { apiFail, apiSuccess } from '../CommonSlice/CommonActions';
import { setDetail, setList, setPackageList } from './PromoSlice'

export const getPromoList = (page=1,filterData={}) =>   async dispatch => {
    try {
        let value = Object.assign({},{page:page},filterData)
        let res = await Api("POST","/admin/promo/list",value)
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(setList(res.data))
            dispatch(setDetail({
                percentage:"",
                code:"",
                type:"",
                packageId:"",
                description:"",
                startDate:"",
                endDate:"",
                status:""
            }))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error)
    }
}
export const getPromoDetail = (value) =>   async dispatch => {
    try {
        let res = await Api("POST","/admin/promo/edit",value)
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(setDetail(res.data))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error)
    }
}
export const getPackageList = () =>   async dispatch => {
    try {
        let res = await Api("POST","/admin/promo/package-list",{})
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(setPackageList(res.data))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error)
    }
}

export const addPromo = (value) => async dispatch =>{
    try {
        let res = await Api("POST",'/admin/promo/add',value)
        if (res.statusCode >=200 && res.statusCode < 300) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error)
    }
}
export const updatePromo = (value) => async dispatch =>{
    try {
        let res = await Api("POST",'/admin/promo/update',value)
        if (res.statusCode >= 200 && res.statusCode < 300) {
            dispatch(apiSuccess(res))
        } else {
            dispatch(apiFail(res))
        }
    } catch (error) {
        console.log('error',error)
    }
}