import React, { useState } from 'react'
import {CKEditor} from 'ckeditor4-react'
export default function CKEdit(props) {
    const [data,setData] = useState(props.defValue)
    const handleChange =(event)=>{
        const data = event.editor.getData()
        setData(data)
        props.getValue(props.name,data);
    }
    return (
        <>
            <CKEditor
                initData={data}
                onChange={handleChange}
            />
            {props.errorStatus?<div className="validation-error-custome">{props.error}</div>:''}  
        </>       
    )
}