import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { updateProfile, updatePassword } from '../../features/AuthSlice/AuthActions'
import { setApiFail, setApiSuccess } from '../../features/CommonSlice/CommonSlice'
const Profile = () => {
    const dispatch = useDispatch()
    var authUser = useSelector((state) => state.authReducer.user);
    const apiSuccess = useSelector(state=>state.commonReducer.success)
    const apiFail = useSelector(state=>state.commonReducer.fail)
    const handelProfileUpdate = (value) => {
        console.log('asdasd',value);
        dispatch(updateProfile(value))
    }
    const handelPassUpdate = (value) => {
        dispatch(updatePassword(value))
    }
    const validationSchemaGeneral = Yup.object().shape({
        firstName:Yup.string().required('First Name is required'),
        lastName:Yup.string().required('Last Name is required'),
        email:Yup.string().required('Email is required').email('Must be a valid email address'),
        mobile:Yup.string().required('Phone number is required').matches(/^[1-9][0-9]{9,13}$/g,'Phone number is not valid')
    })
    const validateSchemaPass = Yup.object().shape({
        old_pass:Yup.string().required('Old password is required'),
        new_pass: Yup.string().required('New password is required'),
        con_pass:Yup.string().required('Confirm Password is required').oneOf([Yup.ref('new_pass')],'confirm password not match to password')
    })
    useEffect(()=>{
        if (apiSuccess) {
          if (apiSuccess.statusCode >= 200 && apiSuccess.statusCode <= 300) {
              toast(apiSuccess.message,{
                  type:'success'
                });
            // reset pass word form 
            document.getElementById("passProfile").reset(); 
            dispatch(setApiSuccess())
          } 
        }
        if (apiFail) {
          if (apiFail.statusCode >= 400 && apiFail.statusCode <= 500) {
            toast(apiFail.message,{
              type:'error'
            });
            dispatch(setApiFail())
          } 
        }
    },[apiFail,apiSuccess])
  return (
    <div className="page-content">
        <div className="section-title">
            <h4>Profile</h4>
        </div>
        <Card>
            <Card.Header>
                Profile Detail
            </Card.Header>
            <Card.Body>
                <Formik
                    enableReinitialize
                    initialValues={{
                        firstName:authUser.data?authUser.data.firstName:"",
                        lastName:authUser.data?authUser.data.lastName:"",
                        email:authUser.data?authUser.data.email:"",
                        mobile:authUser.data?authUser.data.mobile:"",
                      }}
                    validationSchema={validationSchemaGeneral}
                    onSubmit={handelProfileUpdate}
                >
                    {formProps=>(
                        <Form onSubmit={formProps.handleSubmit}>
                            <div className="row">
                                <div className="col-6 mb-2">
                                <Form.Group>
                                    <Form.Label htmlFor="exampleInputName1">First Name</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="First Name" 
                                    name="firstName"
                                    value={formProps.values.firstName}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                    isInvalid={formProps.touched.firstName && formProps.errors.firstName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {formProps.errors.firstName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <div className="col-6 mb-2">
                                <Form.Group>
                                    <Form.Label htmlFor="exampleInputName1">Last Name</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Last Name" 
                                    name="lastName"
                                    value={formProps.values.lastName}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                    isInvalid={formProps.touched.lastName && formProps.errors.lastName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {formProps.errors.lastName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <div className="col-6 mb-2">
                                <Form.Group>
                                    <Form.Label htmlFor="exampleInputEmail3">Email</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Email" 
                                    name="email"
                                    value={formProps.values.email}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                    isInvalid={formProps.touched.email && formProps.errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {formProps.errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <div className="col-6 mb-2">
                                <Form.Group>
                                    <Form.Label htmlFor="exampleInputEmail3">Phone No.</Form.Label>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Phone Number"
                                    name="mobile"
                                    value={formProps.values.mobile}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur} 
                                    isInvalid={formProps.touched.mobile && formProps.errors.mobile}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    {formProps.errors.mobile}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
        <Card className='mt-4'>
            <Card.Header>
                Update Password
            </Card.Header>
            <Card.Body>
                <Formik
                    initialValues={{
                        old_pass:"",
                        new_pass:"",
                        con_pass:""
                    }}
                    validationSchema={validateSchemaPass}
                    onSubmit={handelPassUpdate}
                >
                    {formProps=>(
                        <Form onSubmit={formProps.handleSubmit} onReset={formProps.handleReset} id="passProfile">
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <Form.Group>
                                        <Form.Label>Old Password</Form.Label>
                                        <Form.Control
                                            type='password'
                                            autoComplete={false}
                                            placeholder='Enter Old Password'
                                            name="old_pass"
                                            value={formProps.values.old_pass}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.old_pass && formProps.errors.old_pass}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.old_pass}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-6 mb-2'>
                                    <Form.Group>
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type='password'
                                            autoComplete={false}
                                            placeholder='Enter New Password'
                                            name="new_pass"
                                            value={formProps.values.new_pass}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.new_pass && formProps.errors.new_pass}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.new_pass}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-6 mb-2'>
                                    <Form.Group>
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type='password'
                                            autoComplete={false}
                                            placeholder='Enter Confirm Password'
                                            name="con_pass"
                                            value={formProps.values.con_pass}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            isInvalid={formProps.touched.con_pass && formProps.errors.con_pass}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formProps.errors.con_pass}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary mr-2">Submit</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    </div>
  )
}

export default Profile