import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Navigate } from 'react-router-dom';
import history from '../history';
import Public from "./public/Public";
import Private from "./private/Private";
import { useDispatch,useSelector } from 'react-redux'
import { checkAuth } from '../features/AuthSlice/AuthActions';

export const Index = () => {
    const dispatch = useDispatch()
    const [authrize,setAuthrize] = useState(undefined);
    // const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
    const authUser = useSelector((state) => state.authReducer.user);
    useEffect(() => {
        if(Object.keys(authUser).length==0){
            if (localStorage.getItem('token') && localStorage.getItem('token')!='') {   
                dispatch(checkAuth())
            }else{
                setAuthrize(false);
            }
        }else{
            console.log('authUser',authUser);
            setAuthrize(true)
        }
    }, [authUser])
    return (
        <Router history={history}>
            {
                authrize!=undefined?
                    authrize ?
                        <Private />
                    :
                        <Public />
                :
                    null
            }
            
        </Router>
    )
}
export default Index