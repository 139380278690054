import { createSlice } from '@reduxjs/toolkit';
const getMonthName = (month)=>{
    let monthName = ''
    switch (month) {
        case "01":
            monthName = 'January'
            break;
        case "02":
            monthName = 'February'
            break;
        case "03":
            monthName = 'March'
            break;
        case "04":
            monthName = 'April'
            break;
        case "05":
            monthName = 'May'
            break;
        case "06":
            monthName = 'June'
            break;
        case "07":
            monthName = 'July'
            break;
        case "08":
            monthName = 'August'
            break;
        case "09":
            monthName = 'September'
            break;
        case "10":
            monthName = 'October'
            break;
        case "11":
            monthName = 'November'
            break;
        case "12":
            monthName = 'December'
            break;
        default:
            break;
    }
    return monthName
}
const getWeekName = (day) =>{
    let weekName = ''
    switch (day) {
        case "1":
            weekName = 'Sunday'
            break;
        case "2":
            weekName = 'Monday'
            break;
        case "3":
            weekName = 'Tuesday'
            break;
        case "4":
            weekName = 'Wednesday'
            break;
        case "5":
            weekName = 'Thursday'
            break;
        case "6":
            weekName = 'Friday'
            break;
        case "7":
            weekName = 'Saturday'
            break;
    }
    return weekName
}
const CommonSlice = createSlice({
    name:"CommonSlice",
    initialState:{
        success:null,
        fail:null,
        dashboardCount:{
            bookingCount:0,
            userCount:0   
        },
        bookingChart:[
            ["Total Booking's", "Pending","Inprocess","Completed","Cancelled","Failed"],
        ],
        userChart:[
            ["Total User's", "New User's"],
        ],
        paymentChart:[
            ["Total Payment's", "Pending","Completed","Failed"],
        ],
        refundChart:[
            ["Total Refund's", "Pending","Completed","Failed"],
        ]
    },
    reducers:{
        setApiSuccess:(state,action)=>{
            state.success= action.payload
        },
        setApiFail:(state,action)=>{
            state.fail= action.payload
        },
        setBookingCount:(state,action)=>{
            state.dashboardCount = action.payload
        },
        setBookingChart:(state,action)=>{
            let tempState = [
                ["Total Booking's", "Pending","Inprocess","Completed","Cancelled","Failed"],
            ];
            let temptype = action.payload.type;
            let tempData = action.payload.data;
            if (tempData.length==0) {
                tempState.push(["No Data",0,0,0,0,0]);
            }else{
                for (const item of tempData) {
                    let tempArray=[];
                    if(temptype == 'month'){
                        tempArray.push(getMonthName(item._id));
                    }else if(temptype == 'week'){
                        tempArray.push(getWeekName(item._id));
                    }else{
                        tempArray.push(item._id);
                    }
                    ["Pending", "Inprocess", "Completed", "Cancelled", "Failed"].forEach(status=>{
                        let tempFindData = item.status.find(data=>data.status==status)
                        if (tempFindData) {
                            tempArray.push(tempFindData.count);
                        }else{
                            tempArray.push(0);
                        }
                    });
                    tempState.push(tempArray)
                }
            }
            // console.log('tempState');
            state.bookingChart = tempState
        },
        setUserChart:(state,action)=>{
            let tempState = [
                ["Total User's", "New User's"],
            ];
            let temptype = action.payload.type;
            let tempData = action.payload.data;
            if (tempData.length==0) {
                tempState.push(["No Data",0]);
            } else {
                tempData.forEach(item=>{
                    if(temptype == 'month'){
                        tempState.push([getMonthName(item._id),item.value]);
                    }else if(temptype == 'week'){
                        tempState.push([getWeekName(item._id),item.value]);
                    }else{
                        tempState.push([item._id,item.value])
                    }
                })
            }
            // console.log('tempState');
            state.userChart = tempState
        },
        setPaymentChart:(state,action)=>{
            let tempState = [
                ["Total Payment's", "Pending","Completed","Failed"],
            ];
            let temptype = action.payload.type;
            let tempData = action.payload.data;
            if (tempData.length==0) {
                tempState.push(["No Data",0,0,0]);
            } else {
                for (const item of tempData) {
                    let tempArray=[];
                    if(temptype == 'month'){
                        tempArray.push(getMonthName(item._id));
                    }else if(temptype == 'week'){
                        tempArray.push(getWeekName(item._id));
                    }else{
                        tempArray.push(item._id);
                    }
                    // tempArray.push(getMonthName(item._id));
                    ["Pending", "Completed", "Failed"].forEach(status=>{
                        let tempFindData = item.incomeTotal.find(data=>data.status==status)
                        if (tempFindData) {
                            tempArray.push(tempFindData.total);
                        }else{
                            tempArray.push(0);
                        }
                    });
                    tempState.push(tempArray)
                }    
            }
            // console.log('tempState payment',tempState);
            state.paymentChart = tempState
        },
        setRefundChart:(state,action)=>{
            let tempState = [
                ["Total Refund's", "Pending","Completed","Failed"],
            ];
            let temptype = action.payload.type;
            let tempData = action.payload.data;
            if (tempData.length==0) {
                tempState.push(["No Data",0,0,0]);
            } else {
                for (const item of tempData) {
                    let tempArray=[];
                    if(temptype == 'month'){
                        tempArray.push(getMonthName(item._id));
                    }else if(temptype == 'week'){
                        tempArray.push(getWeekName(item._id));
                    }else{
                        tempArray.push(item._id);
                    }
                    ["Pending", "Completed", "Failed"].forEach(status=>{
                        let tempFindData = item.refundTotal.find(data=>data.status==status)
                        if (tempFindData) {
                            tempArray.push(tempFindData.total);
                        }else{
                            tempArray.push(0);
                        }
                    });
                    tempState.push(tempArray)
                }    
            }
            state.refundChart = tempState
        }
    }
})
export const { setApiSuccess, setApiFail,setBookingCount, setBookingChart,setUserChart,setPaymentChart,setRefundChart } = CommonSlice.actions

export default CommonSlice.reducer
