import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getExtraData, getPackageList } from "../../features/PackageSlice/PackageActions";
import Pagination from '../commonComponents/pagination/serverSide'
const Packages = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const apiFail = useSelector((state) => state.commonReducer.fail);
	const [loading, setLoading ] = useState(true)
    const [list,setList] = useState([])
    const [paginationData, setPaginationData] = useState({})
	const packageList = useSelector((state) => state.PackageReducer.packageList);
	const packageExtraData = useSelector(state=>state.PackageReducer.packageExtraData)
    const [filterData,setFilterData] = useState({
        city:"",
		vehicleType:""
    })
	const handelFilter = (values) =>{
        setFilterData(values)
		setLoading(true)
        dispatch(getPackageList(1,values))
    }
	const pageChange = (page) => {
        setLoading(true)
        dispatch(getPackageList(page,filterData))
    }
	useEffect(() => {
		dispatch(getPackageList());
		dispatch(getExtraData())
	}, []);
	useEffect(()=>{
		if (Object.keys(packageList).length!=0) {
			setList(packageList.docs)
            setPaginationData({
                totalDocs: packageList.totalDocs,
                limit: packageList.limit,
                page: packageList.page,
                totalPages: packageList.totalPages,
                hasPrevPage: packageList.hasPrevPage,
                hasNextPage: packageList.hasNextPage,
                prevPage: packageList.prevPage,
                nextPage: packageList.nextPage
            })
            setLoading(false)
		}
	},[packageList])

	return (
		<div className="page-content">
			<div className="section-title">
				<h4>Packages</h4>
			</div>
			<div className="row">
				<div className="col-12 mb-2">
					<Card>
						<Card.Body>
							<Formik
								initialValues={filterData}
								onSubmit={handelFilter}
								onReset={handelFilter}
							>
								{
									formProps=>(
										<Form onSubmit={formProps.handleSubmit} onReset={formProps.handleReset}>
											<div className="row">
												<div className="col-4">
													<Form.Group>
														<Form.Label>City</Form.Label>
														<Form.Select
															name="city"
															value={formProps.values.city}
															onChange={formProps.handleChange}
														>
															<option> Select City</option>
															{
																packageExtraData.city.map(option=>(
																	<option key={option.value} value={option.value}>{option.label}</option>
																))
															}
														</Form.Select>
													</Form.Group>
												</div>
												<div className="col-4">
													<Form.Group>
														<Form.Label>Vehicle Type</Form.Label>
														<Form.Select
															name="vehicleType"
															value={formProps.values.vehicleType}
															onChange={formProps.handleChange}
														>
															<option> Select Vehicle Type</option>
															{
																packageExtraData.vehicleType.map(option=>(
																	<option key={option.value} value={option.value}>{option.label}</option>
																))
															}
														</Form.Select>
													</Form.Group>
												</div>
												<div className="col-4">
													<Form.Group>
														<label className='form-label d-block opacity-0 mt-1' htmlFor="phoneFilter">Action</label>
														<button type="submit" className="btn btn-sm btn-success ml-2">Filter</button>
														<button type="reset" className="btn btn-sm btn-info ms-2" >Reset</button>
													</Form.Group>
												</div>
											</div>
										</Form>
									)
								}
								
							</Formik>
						</Card.Body>
					</Card>
				</div>
				<div className="col-12">
					<Card>
						<Card.Header>
							<div className="row">
								<div className="col text-end">
									<Button
										onClick={() => navigate("/admin/package/add")}
										variant="primary"
									>
										Add Package
									</Button>
								</div>
							</div>
						</Card.Header>
						<Card.Body>
							<div className="row">
								<div className="table-responsive">
									<table className="table table-bordered tablespace">
										<thead>
											<tr>
												<th scope="col">#</th>
												<th scope="col">Title</th>
												<th scope="col">Price</th>
												<th scope="col">City</th>
												<th scope="col">Vehicle Type</th>
												<th scope="col">Status</th>
												<th scope="col">CreatedOn</th>
												<th scope="col">Action</th>
											</tr>
										</thead>
										<tbody>
											{
												loading?
													<tr>
														<td colSpan={8}>
															Loading....
														</td>
													</tr>
												:
													list.length==0?
														<tr>
															<td colSpan={8}>
																No data Found
															</td>
														</tr>
													:
														list.map((item, index) => (
															<tr key={index}>
																<th scope="row">
																	{
                                                                        (
                                                                            (paginationData.page - 1)* paginationData.limit 
                                                                        ) + index +1
                                                                    } 
																</th>
																<td>{item.title}</td>
																<td>{item.price}</td>
																<td>{item?.city?.title}</td>
																<td>{item?.vehicleType?.title}</td>
																<td>
																	{item.status ? (
																		<Badge bg="success">Active</Badge>
																	) : (
																		<Badge bg="danger">InActive</Badge>
																	)}
																</td>
																<td>{moment(item.createdAt).format("ll")}</td>
																<td>
																	{/* <Link to={`/admin/package/view/${item._id}`}>
																		<i className="mx-2 far fa-eye"></i>
																	</Link> */}
																	<Link to={`/admin/package/edit/${item._id}`}>
																		<i className="fas fa-edit"></i>
																	</Link>
																</td>
															</tr>
														))
											}
										</tbody>
									</table>
								</div>
							</div>
						</Card.Body>
						<Card.Footer>
							<Pagination
								data={paginationData}
								pagFun={pageChange}
							/>
						</Card.Footer>
					</Card>
				</div>
			</div>

		</div>
	);
};

export default Packages;
