import React from 'react'
import { Pagination } from 'react-bootstrap';
export default function Server({data,pagFun}) {
    let paginationLink=[];
    if (!data.page) {
        paginationLink.push(
            <Pagination.Item 
                key="0" 
                className="active"
                onClick={
                    (e) => e.preventDefault()
                }
            >
                {/* <span
                    onClick={
                        (e) => {
                            e.preventDefault()
                        }
                    }
                > */}
                    1
                {/* </span> */}
            </Pagination.Item>
        )
    } else {
        for (let index = 1; index <= data.totalPages; index++) {
            paginationLink.push(
                <Pagination.Item
                    key={index} 
                    className={`${(index==data.page)&&"active"}`}
                    onClick={
                        (e) => {
                            e.preventDefault()
                            if (index!=data.page)
                                pagFun(index)
                        }
                    }
                >
                        {index}
                </Pagination.Item>
            )
        }
    }
    
    return (
        <>
            <Pagination
                className="justify-content-end"
            >
                
                <Pagination.Prev 
                    className={`${(!data.hasPrevPage)&&"disabled"}`}
                    onClick={(e) => {
                        e.preventDefault();
                        if (data.hasPrevPage)
                            pagFun(data.prevPage)
                        }
                    }
                    tabIndex="-1"
                >
                    {/* <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span> */}
                </Pagination.Prev>
                {paginationLink}
                <Pagination.Next 
                    className={`${(!data.hasNextPage)&&"disabled"}`}
                    onClick={(e) => {
                        e.preventDefault()
                        if (data.hasNextPage)
                            pagFun(data.nextPage)
                    }
                }
                >
                    {/* <PaginationLink
                        
                    >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                    </PaginationLink> */}
                </Pagination.Next>
            </Pagination>
        </>
    )
}