import { createSlice } from '@reduxjs/toolkit';


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {},
        isAuthenticated: false,
        errorMessage: "",
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setAuthSuccess: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        setAuthFailed: (state, action) => {
            state.user = {}
            state.errorMessage = action.payload;
            state.isAuthenticated = false
        },
    },
})
// Action creators are generated for each case reducer function
export const { setAuthSuccess, setLoading, setAuthFailed } = authSlice.actions

export default authSlice.reducer
